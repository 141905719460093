import React from "react";

import './PageContentColumn.css';
import classNames from "classnames";

export default function PageContentColumn(props) {

    const cx = classNames({
        "page-content-column": true,
        "center": props['align'] && props['align'] === 'center',
        "right": props['align'] && props['align'] === 'right',
        "vcenter": props['verticalAlign'] && props['verticalAlign'] === 'center',
        "vbottom": props['verticalAlign'] && props['verticalAlign'] === 'bottom',
        ...(!!props['className'] ? {
            [props['className']]: props['className']
        } : {})
    });

    return (
        <div id={props['id']} className={cx} style={props['style']}>
            {props.children}
        </div>
    )
}
