import React, {useEffect, useState} from "react";

const defaultValue = {
    invertHeader: false
}

export const AppearanceContext = React.createContext(defaultValue);

export function Appearance(props){

    const invertHeader = useState(defaultValue.invertHeader);

    const value = {
        invertHeader: invertHeader
    }

    useEffect(() => {

        const tag = document.querySelector('meta[name="theme-color"]');
        if(!tag) return;

        tag.setAttribute('content', invertHeader ? "#FFFFFF" : "#000014");

    }, [invertHeader]);

    return (
        <AppearanceContext.Provider value={value}>
            {props.children}
        </AppearanceContext.Provider>
    )
}
