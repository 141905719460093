import React, {useContext, useEffect, useImperativeHandle, useRef, useState} from "react";

import './EditStockForm.css';
import CustomSelect from "../CustomSelect/CustomSelect";
import classNames from "classnames";
import axios from "axios";
import Checkbox from "../Checkbox/Checkbox";
import LoadingIcon from "../LoadingIcon/LoadingIcon";
import {UserContext} from "../../user";
import CustomFileInput from "../CustomFileInput/CustomFileInput";

const EditStockForm = React.forwardRef((props, ref) => {

    const data = props['data'];
    const user = useContext(UserContext);

    const [resetCounter, setResetCounter] = useState(0);

    const [collapse, setCollapse] = useState(props['collapse']);
    const [savingProgress, setSavingProgress] = useState(0);

    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [trims, setTrims] = useState([]);

    const [edited, setEdited] = useState(false);
    const [formIsValid, setFormIsValid] = useState(false);

    const [status, _setStatus] = useState(!!data?.is_sold);
    const [make, _setMake] = useState(data?.make ?? "");
    const [model, _setModel] = useState(data?.model ?? "");
    const [trim, _setTrim] = useState(data?.trim ?? "");
    const [engineCapacity, _setEngineCapacity] = useState(data?.engine_capacity ?? "");
    const [year, _setYear] = useState(data?.year ?? "");
    const [description, _setDescription] = useState(data?.description ?? "");
    const [mileage, _setMileage] = useState(data?.mileage ?? "");
    const [color, _setColor] = useState(data?.color ?? "");
    const [generalColor, _setGeneralColor] = useState(data?.general_color ?? "");
    const [price, _setPrice] = useState(!isNaN(parseFloat(data?.price)?.toFixed(2)) ? parseFloat(data?.price)?.toFixed(2) : 0);
    const [apr, _setApr] = useState(!data ? 0 : (data?.apr));
    const [autotraderURL, _setAutotraderURL] = useState(data?.autotrader_url ?? "");
    const [youtubeURL, _setYoutubeURL] = useState(data?.youtube_url ?? "");
    const [deletePassword, setDeletePassword] = useState("");
    const [addPhotos, setAddPhotos] = useState(false);

    const [formError, setFormError] = useState("");
    const [formResponse, setFormResponse] = useState("");

    const [deleteError, setDeleteError] = useState("");

    const [animateExpand, setAnimateExpand] = useState(!!props['animate']);

    const [colorHasFocus, setColorHasFocus] = useState(false);
    const [generalColorHasFocus, setGeneralColorHasFocus] = useState(false);

    const [showApr, _setShowApr] = useState((!data || (data?.apr !== undefined && data?.apr !== null)) && !status);


    const form = useRef();
    const filesRef = useRef();
    const allowHirePurchaseRef = useRef();


    const reset = collapseOnReset => {
        if (!data) {
            form?.current.reset();
        } else {
            _setStatus(!!data?.is_sold);
            _setMake(data?.make ?? "");
            _setModel(data?.model ?? "");
            _setTrim(data?.trim ?? "");
            _setEngineCapacity(data?.engine_capacity ?? "");
            _setYear(data?.year ?? "");
            _setDescription(data?.description ?? "");
            _setMileage(data?.mileage ?? "");
            _setColor(data?.color ?? "");
            _setGeneralColor(data?.general_color ?? "");
            _setPrice(!isNaN(parseFloat(data?.price)?.toFixed(2)) ? parseFloat(data?.price)?.toFixed(2) : 0);
            _setApr(data?.apr);
            _setAutotraderURL(data?.autotrader_url ?? "");
            _setYoutubeURL(data?.youtube_url ?? "");
            setDeletePassword("");
            if (filesRef?.current?.length === 0) {
                setAddPhotos(false);
            }
        }
        setEdited(false);
        if (collapseOnReset) {
            setCollapse(true);
        }
        setResetCounter(resetCounter + 1);
    }

    const setStatus = value => {
        setEdited(true);
        if (value) {
            setShowApr(false);
            allowHirePurchaseRef.current.checked = false;
            allowHirePurchaseRef.current.onChange();
        }
        _setStatus(value);
    }

    const setMake = value => {
        setEdited(true);
        _setMake(value);
    }

    const setModel = value => {
        setEdited(true);
        _setModel(value);
    }

    const setTrim = value => {
        setEdited(true);
        _setTrim(value);
    }

    const setEngineCapacity = value => {
        setEdited(true);
        _setEngineCapacity(value);
    }

    const setYear = value => {
        setEdited(true);
        _setYear(value);
    }

    const setDescription = value => {
        setEdited(true);
        _setDescription(value);
    }

    const setMileage = value => {
        setEdited(true);
        _setMileage(value);
    }

    const setColor = value => {
        setEdited(true);
        _setColor(value);
    }

    const setGeneralColor = value => {
        setEdited(true);
        _setGeneralColor(value);
    }

    const setPrice = value => {
        setEdited(true);
        _setPrice(value);
    }

    const setAutotraderURL = value => {
        setEdited(true);
        _setAutotraderURL(value);
    }

    const setYoutubeURL = value => {
        setEdited(true);
        _setYoutubeURL(value);
    }

    const setApr = value => {
        setEdited(true);
        if (value.toString().match(/[0-9\.]*/) && (value.toString().match(/\./g) || []).length <= 1) _setApr(value);
    }

    const setShowApr = value => {
        if (status) {
            _setShowApr(false);
            return;
        }
        if (value) {
            if (!!data && data.apr !== undefined && data.apr !== null && data.apr !== "") {
                setApr(data.apr);
            } else {
                setApr(0);
            }
        } else {
            setEdited(true);
            _setApr(null);
        }
        _setShowApr(value);
    }

    useEffect(() => {

        setCollapse(props['collapse']);

    }, [props['collapse']]);

    useEffect(() => {

        if (collapse || animateExpand) {
            reset();
        }

        let mounted = true;

        if (animateExpand) {
            setTimeout(() => {
                if (mounted) {
                    setAnimateExpand(false);
                }
            }, 100);
        }

        return () => {
            mounted = false;
        }

    }, [props, animateExpand])

    const createNewListingFormCx = classNames({
        "edit-stock-form": true,
        "block": true,
        "collapse": animateExpand || collapse
    });

    const handleSubmit = e => {
        if (props['onSubmit'] && typeof props['onSubmit'] === "function") {
            props['onSubmit'](e);
        }
    }

    const handleCancel = e => {
        if (!edited || window.confirm("All current progress will be lost. Do you wish to continue?")) {
            if (props['onCancel'] && typeof props['onCancel'] === "function") {
                props['onCancel'](e);
            }
        }
    }

    const handleDelete = e => {
        setDeleteError("");
        setSavingProgress(true);
        if (!data.stock_id || !user.username) {
            return;
        }
        const deleteData = {
            stock_id: data.stock_id
        }
        axios.delete(`${process.env.REACT_APP_API_URL}/v1/admin/stock/${data.stock_id}`, {
            auth: {
                username: user.username[0],
                password: deletePassword
            },
            data: deleteData
        })
            .then(response => {
                switch (response.status) {
                    case 200:
                        if (props['onDelete'] && typeof props['onDelete'] === "function") {
                            props['onDelete'](e);
                        }
                        break;
                    case 401:
                        setDeleteError("Password is incorrect");
                        break;
                    default:
                        setDeleteError("Could not delete listing. Please try again later");
                }
                setSavingProgress(false);
            })
            .catch(err => {
                switch (err.response.status) {
                    case 401:
                        setDeleteError("Password is incorrect");
                        break;
                    default:
                        if (err.message) {
                            setDeleteError(err.message);
                        } else {
                            setDeleteError("Could not delete listing. Please try again later");
                        }
                }
                setSavingProgress(false);
            });

    }

    useImperativeHandle(ref, () => ({
        reset: reset,
        setError: setFormError,
        setSaving: setSavingProgress,
        setResponse: setFormResponse
    }));

    useEffect(() => {
        setFormResponse("");
    }, [status, make, model, trim, engineCapacity, year, description, mileage, color, generalColor, price, autotraderURL, youtubeURL]);

    useEffect(() => {
        setFormIsValid(form.current?.querySelectorAll('input[type="text"]:invalid, textarea:invalid, select:invalid').length === 0);
    }, [form.current, make, model, trim, description, mileage, engineCapacity, year, generalColor, price, edited]);

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_API_URL}/v1/stock/filters`)
            .then(response => {
                const data = response.data.data;
                if (data.make) setMakes(data.make);
                if (data.model) setModels(data.model);
                if (data.trim) setTrims(data.trim);
            })
            .catch(error => {
                const response = error.response;
                setFormError(response.message ?? "Could not retrieve stock information");
            });

    }, []);

    useEffect(() => {
        let mounted = true;
        for (let i = 0; i < data?.media?.length; i++) {
            axios.get(`${process.env.REACT_APP_API_URL}/v1/media/stock/${data.media[i].media_id}`, {
                responseType: 'blob'
            })
                .then(response => {
                    let reader = new FileReader();
                    reader.addEventListener("load", e => {
                        if (mounted) {
                            filesRef?.current?.addFile(e.target.result, data.media[i]?.media_id);
                            setAddPhotos(true);
                        }
                    });
                    reader.readAsDataURL(response.data);
                })
        }
        return () => {
            mounted = false;
        }
    }, [resetCounter]);

    const warnBeforeClose = e => {
        const message = "Are you sure you want to leave? All changes will be lost.";
        if (!!e) e.returnValue = message;
        else if (!!window.event) window.event.returnValue = message;
        return message;
    }

    useEffect(() => {
        window.addEventListener("beforeunload", warnBeforeClose);

        return () => {
            window.removeEventListener("beforeunload", warnBeforeClose);
        }
    });

    const htmlID = !!data?.stock_id ? "stock-" + data.stock_id.toString() : "new-listing";

    return (
        <form className={createNewListingFormCx} ref={form} onSubmit={handleSubmit}>
            {savingProgress > 0 && savingProgress < 100 &&
                <LoadingIcon label={!!data ? "Saving changes..." : "Creating new listing..."}
                             progress={savingProgress}/>}
            <h4>{!!data ? `${make} ${model} ${trim} ${year} – ${generalColor}` : "Create a new listing for Legacy Cars."}</h4>
            <p className={"response"}>{formResponse}</p>
            <p className={"error"}>{formError}</p>
            {!!data && (
                <>
                    <input type={"hidden"} name={"stock_id"} value={data.stock_id}/>
                    <Checkbox label={"Mark as sold"} name={"is_sold"} value={"true"} defaultChecked={status}
                              onChange={e => setStatus(e.target.checked)}/>
                </>
            )}
            <label htmlFor={`${htmlID}-make-input`}>Select a make</label>
            <CustomSelect id={`${htmlID}-make-input`} defaultValue={make} name={"make"} placeholder={"Make"}
                          onChange={e => setMake(e.target.value)} required>
                {makes.map((value, index) => (
                    <option key={`stock-make-${index}-${value}`} value={value}>{value}</option>
                ))}
            </CustomSelect>
            <label htmlFor={`${htmlID}-model-input`}>Select a model</label>
            <CustomSelect id={`${htmlID}-model-input`} defaultValue={model} name={"model"} placeholder={"Model"}
                          onChange={e => setModel(e.target.value)} required>
                {models.map((value, index) => (
                    <option key={`stock-model-${index}-${value}`} value={value}>{value}</option>
                ))}
            </CustomSelect>
            <label htmlFor={`${htmlID}-trim-input`}>Select a trim</label>
            <CustomSelect id={`${htmlID}-trim-input`} defaultValue={trim} name={"trim"} placeholder={"Trim"}
                          onChange={e => setTrim(e.target.value)} required>
                {trims.map((value, index) => (
                    <option key={`stock-trim-${index}-${value}`} value={value}>{value}</option>
                ))}
            </CustomSelect>

            <label htmlFor={`${htmlID}-engine-capacity-input`}>What is the engine cylinder capacity (cc)?</label>
            <input type={"text"} id={`${htmlID}-engine-capacity-input`} name={"engine_capacity"}
                   placeholder={"Engine capacity"} value={engineCapacity}
                   onChange={e => setEngineCapacity(e.target.value)} pattern={"[0-9]{3,5}"} maxLength={5} required/>
            <label htmlFor={`${htmlID}-year-input`}>What year was the vehicle made?</label>
            <input type={"text"} id={`${htmlID}-year-input`} name={"year"} placeholder={"Year"} value={year}
                   onChange={e => setYear(e.target.value)} pattern={"[0-9]{4}"} maxLength={4} required/>
            <label htmlFor={`${htmlID}-color-input`}>What is the manufacturer-labelled colour for the vehicle?</label>
            {colorHasFocus && (
                <p className={"response"}>List the vehicle's colour as labelled or described by the manufacturer</p>
            )}
            <input type={"text"} id={`${htmlID}-color-input`} name={"color"} placeholder={"Manufacturer paint colour"}
                   value={color} onChange={e => setColor(e.target.value)} maxLength={32}
                   onFocus={() => setColorHasFocus(true)} onBlur={() => setColorHasFocus(false)} required/>
            <label htmlFor={`${htmlID}-general-color-input`}>What general colour is the vehicle?</label>
            {generalColorHasFocus && (
                <p className={"response"}>List the vehicle's colour in plain-speak, without branded names or marketed
                    labelling</p>
            )}
            <input type={"text"} id={`${htmlID}-general-color-input`} name={"general_color"}
                   placeholder={"General colour"} value={generalColor} onChange={e => setGeneralColor(e.target.value)}
                   maxLength={32} onFocus={() => setGeneralColorHasFocus(true)}
                   onBlur={() => setGeneralColorHasFocus(false)} required/>
            <label htmlFor={`${htmlID}-mileage-input`}>What is the mileage on this vehicle (mi)?</label>
            <input type={"text"} id={`${htmlID}-mileage-input`} placeholder={"Mileage"} name={"mileage"} value={mileage}
                   onChange={e => setMileage(e.target.value)} maxLength={11} pattern={"([0-9]+)"} required/>
            <label htmlFor={`${htmlID}-price-input`}>Define a list price (GBP &pound;)</label>
            <input type={"text"} id={`${htmlID}-price-input`} name={"price"} placeholder={"List price"} value={price}
                   onChange={e => setPrice(e.target.value)} pattern={"([0-9]{1,13}(.[0-9]{2})?)"} maxLength={16}
                   required/>
            <br/>
            <Checkbox label={"Allow hire purchase"} name={"hire_purchasable"} value={"true"}
                      defaultChecked={(!data || (data?.apr !== undefined && data?.apr !== null)) && !status}
                      onChange={e => setShowApr(e.target.checked)} disabled={status} ref={allowHirePurchaseRef}/>
            <span className={"edit-stock-form-apr-container"}>
                {
                    showApr && (
                        <>
                            <label htmlFor={`${htmlID}-apr-input`}>What is the Annual Percentage Rate (APR) of interest?
                                (%)</label>
                            <input type={"text"} id={`${htmlID}-apr-input`} name={"apr"} placeholder={"APR"} value={apr}
                                   onChange={e => setApr(e.target.value)} pattern={"^[0-9]+(\.[0-9]+)?"}/>
                        </>
                    )
                }
            </span>
            <br/>
            <label htmlFor={`${htmlID}-description-input`}>Provide a description</label>
            <textarea id={`${htmlID}-description-input`} placeholder={"Description"} name={"description"}
                      value={description} onChange={e => setDescription(e.target.value)} maxLength={16384} required/>
            <CustomFileInput accept={"image/jpg,image/jpeg,image/png"} label={"+ Add Photos"} multiple={true}
                             showPlaceholders={addPhotos} maxSize={8388608} onClick={e => {
                setAddPhotos(true);
                e.nativeEvent.stopImmediatePropagation();
                e.preventDefault();
            }} onChange={() => setEdited(true)} ref={filesRef} name={"images"}/>
            <br/>
            <h4>External links</h4>
            <label htmlFor={`${htmlID}-autotrader-input`}>Link to AutoTrader</label>
            {!!autotraderURL && !autotraderURL.match(/^(http)s?:\/\/(www\.)?autotrader\.co\.uk\/car-details\/\d+/) && (
                <p className={"response"}>https://www.autotrader.co.uk/car-details/{"<advert_id>"}</p>)}
            <input type={"text"} id={`${htmlID}-autotrader-input`} name={"autotrader_url"}
                   placeholder={"Link to AutoTrader advert"} maxLength={512} value={autotraderURL}
                   onChange={e => setAutotraderURL(e.target.value)}/>
            {!!autotraderURL && (
                <>
                    <br/>
                    <a href={autotraderURL} target={"_blank"} rel={"noreferrer"}>
                        <input type={"button"} value={"Test AutoTrader Link \u2197"}
                               disabled={!(autotraderURL.match(/^(http)s?:\/\/(www\.)?autotrader\.co\.uk\/car-details\/\d+/))}
                               maxLength={64}/>
                    </a>
                    <br/>
                </>
            )}
            <label htmlFor={`${htmlID}-youtube-input`}>Link to YouTube video</label>
            {!!youtubeURL && !youtubeURL.match(/^(http)s?:\/\/(www\.)?((youtube\.(com|co\.uk)\/watch(\/)?\?v=)|(youtu\.be\/))([A-Za-z0-9_\-]{11})$/) && (
                <p className={"response"}>https://www.youtube.com/watch/?v={"<video_id>"}</p>)}
            <input type={"text"} id={`${htmlID}-youtube-input`} name={"youtube_url"}
                   placeholder={"Link to YouTube video"} maxLength={44} value={youtubeURL}
                   onChange={e => setYoutubeURL(e.target.value)}/>
            {!!youtubeURL && (
                <>
                    <br/>
                    <a href={youtubeURL} target={"_blank"} rel={"noreferrer"}>
                        <input type={"button"} value={"Test YouTube Link \u2197"}
                               disabled={!(youtubeURL.match(/^(http)s?:\/\/(www\.)?((youtube\.(com|co\.uk)\/watch(\/)?\?v=)|(youtu\.be\/))([A-Za-z0-9_\-]{11})$/))}/>
                    </a>
                </>
            )}
            <span className={"horizontal"}>
                <input type={"submit"} value={(!!data ? "Save Changes" : "+ Add Listing")}
                       disabled={!formIsValid || !edited}/>
                <input type={"button"} value={"Cancel"} onClick={handleCancel}/>
            </span>
            {
                !!data && (
                    <>
                        <br/>
                        <br/>
                        <h4>Delete listing</h4>
                        <p className={"error"}>{deleteError}</p>
                        <label htmlFor={`${htmlID}-delete-listing-password-input`}>Enter your password to delete this
                            listing</label>
                        <input type={"password"} id={`${htmlID}-delete-listing-password-input`} placeholder={"Password"}
                               name={"password"} value={deletePassword} onChange={e => setDeletePassword(e.target.value)}/>
                        <input type={"button"} className={"destructive"} value={"Delete Listing"} onClick={e => {
                            if (window.confirm("Are you sure you want to delete this listing? This action cannot be undone.")) handleDelete(e);
                        }} disabled={!deletePassword}/>
                    </>
                )
            }
        </form>
    )

});

export default EditStockForm;
