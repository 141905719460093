import React, {useContext, useEffect} from 'react';
import PageContentColumn from "../../Components/PageContentColumn/PageContentColumn";
import PageContent from "../../Components/PageContent/PageContent";
import Page from "../../Components/Page/Page";
import {UserContext} from "../../user";
import LoadingIcon from "../../Components/LoadingIcon/LoadingIcon";

export default function Logout(props) {

    const user = useContext(UserContext);

    const [loggedIn, setLoggedIn] = user['loggedIn'];
    const [auth, setAuth] = user['auth'];
    const [username, setUsername] = user['username'];

    useEffect(() => {
        setAuth("");
        setUsername("");
        setLoggedIn(false);
    });

    return (
        <Page>
            <PageContent>
                <PageContentColumn>
                    <h3>Just a moment.</h3>
                    <p>We're signing you out of Legacy Cars.</p>
                    <LoadingIcon inline label={"Signing out..."}/>
                </PageContentColumn>
            </PageContent>
        </Page>
    )

}
