import React, {useImperativeHandle, useState} from "react";

import './HamburgerIcon.css';
import classNames from "classnames";

const HamburgerIcon = React.forwardRef((props, ref) => {

    const [close, setClose] = useState(false);

    useImperativeHandle(ref, () => ({
        "setClosed": setClose
    }));

    const handleClick = e => {
        setClose(!close);
        if (!!props['onClick'] && typeof props['onClick'] === 'function') {
            props['onClick'](e);
        }
    }

    const cx = classNames({
        "hamburger-icon": true,
        "close": close,
        "invert": !!props['invert']
    });

    return (
        <div className={cx} onClick={handleClick}>
            <div className={"layer"}/>
            <div className={"layer"}/>
        </div>
    )
});

export default HamburgerIcon;
