import React, {useContext, useEffect, useState} from "react";

import './Accounts.css';
import Page from "../../Components/Page/Page";
import PageContent from "../../Components/PageContent/PageContent";
import PageContentColumn from "../../Components/PageContentColumn/PageContentColumn";
import PageContentRow from "../../Components/PageContentRow/PageContentRow";
import axios from "axios";
import {UserContext} from "../../user";
import LoadingIcon from "../../Components/LoadingIcon/LoadingIcon";
import {Link} from "react-router-dom";

export default function Accounts(props) {

    const user = useContext(UserContext);

    const [username, setUsername] = useState("");
    const [currentUsername, setCurrentUsername] = useState();
    const [usernameLoading, setUsernameLoading] = useState(true);
    const [usernameResponse, setUsernameResponse] = useState("");
    const [usernameError, setUsernameError] = useState("");

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [passwordLoading, setPasswordLoading] = useState(false);
    const [passwordResponse, setPasswordResponse] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const saveUsername = e => {
        e.preventDefault();
        setUsernameLoading(true);
        setUsernameResponse("");
        setUsernameError("");
        axios.patch(`${process.env.REACT_APP_API_URL}/v1/auth`, {
            username: username
        }, {
            headers: {
                Authorization: `Basic ${user['auth'][0]}`
            }
        })
            .then(response => {
                setUsernameLoading(false);
                const data = response.data.data;
                switch (response.status) {
                    case 200:
                        setUsername(data.username);
                        setCurrentUsername(data.username);
                        const setUserUsername = user['username'][1];
                        setUserUsername(data.username);
                        setUsernameResponse("Changes saved successfully");
                        break;
                    default:
                        setUsernameError("Could not update user. Please try again later");
                }
            })
            .catch(error => {
                setUsernameLoading(false);
                const response = error.response;
                setUsernameError((response.data.message ?? "Could not update user. Please try again later"));
            });
    }

    const savePassword = e => {
        e.preventDefault();
        setPasswordLoading(true);
        setPasswordResponse("");
        setPasswordError("");
        if (newPassword !== confirmNewPassword) return;
        axios.patch(`${process.env.REACT_APP_API_URL}/v1/auth`, {
            password: newPassword
        }, {
            headers: {
                Authorization: `Basic ${btoa([currentUsername, currentPassword].join(':'))}`
            }
        })
            .then(response => {
                setPasswordLoading(false);
                switch (response.status) {
                    case 200:
                        setPasswordResponse("Password changed successfully");
                        setCurrentPassword("");
                        setNewPassword("");
                        setConfirmNewPassword("");
                        const [userAuth, setUserAuth] = user['auth'];
                        setUserAuth(btoa([currentUsername, newPassword].join(':')))
                        break;
                    default:
                        setPasswordError("Could not update password. Please try again later");
                }
            })
            .catch(error => {
                setPasswordLoading(false);
                const response = error.response;
                switch (response?.status) {
                    case 401:
                        setPasswordError("Password is incorrect. Please try again");
                        break;
                    default:
                        setPasswordError(response.data.message ?? "Could not update password. Please try again later");
                }
            })
    }

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_API_URL}/v1/auth`, {
            headers: {
                Authorization: `Basic ${user['auth'][0]}`
            },
            params: {
                username: user['username'][0]
            }
        })
            .then(response => {
                const currentUser = response.data.data;
                setCurrentUsername(currentUser.username);
                setUsername(currentUser.username);
                setUsernameLoading(false);
            })
            .catch(error => {
                setUsernameError("Could not retrieve user information");
                setUsernameLoading(false);
            });

    }, []);

    return (
        <Page>
            <PageContent>
                <PageContentRow>
                    <PageContentColumn>
                        <h2>Authorize and manage accounts on Legacy Cars.</h2>
                        <p className={"center"}>Change your user account information.</p>
                    </PageContentColumn>
                </PageContentRow>
                <PageContentRow>
                    <PageContentColumn>
                        <p><Link to={"/"}>&lsaquo; Dashboard</Link></p>
                        <h3>Your profile information.</h3>
                        <p>Change your username or password.</p>
                        <form className={"block"} onSubmit={saveUsername}>
                            <h4>Username</h4>
                            <p className={"response"}>{usernameResponse}</p>
                            <p className={"error"}>{usernameError}</p>
                            <label htmlFor={"username-input"}>New username</label>
                            <input type={"text"} id={"username-input"} placeholder={"Username"} value={username}
                                   onChange={e => setUsername(e.target.value)}/>
                            <input type={"submit"} value={"Save Changes"}
                                   disabled={currentUsername === username || !username} required/>
                            {usernameLoading && <LoadingIcon/>}
                        </form>
                        <form className={"block"} onSubmit={savePassword}>
                            <h4>Password</h4>
                            <p className={"response"}>{passwordResponse}</p>
                            <p className={"error"}>{passwordError}</p>
                            <label htmlFor={"current-password-input"}>Current password</label>
                            <input type={"password"} id={"current-password-input"} placeholder={"Current password"}
                                   value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} required/>
                            <br/>
                            {newPassword !== confirmNewPassword && !!newPassword && !!confirmNewPassword &&
                                <p className={"error"}>Passwords do not match</p>}
                            <label htmlFor={"new-password-input"}>New password</label>
                            <input type={"password"} id={"new-password-input"} placeholder={"New password"}
                                   value={newPassword} onChange={e => setNewPassword(e.target.value)} required/>
                            <label htmlFor={"confirm-new-password-input"}>Confirm new password</label>
                            <input type={"password"} id={"confirm-new-password-input"}
                                   placeholder={"Confirm new password"} value={confirmNewPassword}
                                   onChange={e => setConfirmNewPassword(e.target.value)} required/>
                            <input type={"submit"} value={"Save Changes"}
                                   disabled={!currentPassword || !newPassword || !confirmNewPassword || newPassword !== confirmNewPassword || btoa([currentUsername, newPassword].join(':')) === user['auth'][0]}/>
                            {passwordLoading && <LoadingIcon/>}
                        </form>
                    </PageContentColumn>
                </PageContentRow>
            </PageContent>
        </Page>
    )

}
