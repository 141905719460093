import React, {useContext, useEffect} from "react";

import './Page.css';

import {AppearanceContext} from "../../appearance";
import Footer from "../Footer/Footer";
import classNames from "classnames";

export default function Page(props) {

    const appearance = useContext(AppearanceContext);

    const [invertHeader, setInvertHeader] = appearance.invertHeader;

    useEffect(() => {

        setInvertHeader(!!props['invertHeader']);

    }, [props['invertHeader']])

    const cx = classNames({
        'page': true,
        [props.className]: !!props.className
    });

    return (
        <div className={cx}>
            {props.children}
            <Footer/>
        </div>
    )

}
