import React, {useContext} from 'react';

import './Dashboard.css';
import Page from "../../Components/Page/Page";
import PageContent from "../../Components/PageContent/PageContent";
import PageContentRow from "../../Components/PageContentRow/PageContentRow";
import PageContentColumn from "../../Components/PageContentColumn/PageContentColumn";
import {UserContext} from "../../user";
import {Link} from "react-router-dom";

export default function Dashboard(props) {

    const user = useContext(UserContext);

    const date = new Date();
    const hours = date.getHours();
    let timeOfDay = "evening";
    if (hours >= 4 && hours < 12) {
        timeOfDay = "morning";
    } else if (hours >= 12 && hours < 17) {
        timeOfDay = "afternoon";
    }

    return (
        <Page>
            <PageContent>
                <PageContentRow>
                    <PageContentColumn>
                        <h3>Good {timeOfDay}, welcome to your dashboard.</h3>
                        <p>How can I help?</p>
                    </PageContentColumn>
                </PageContentRow>
                <PageContentRow>
                    <PageContentColumn>
                        <form className={"horizontal dashboard-menu"}>
                            <Link to={'/stock'}>
                                <input type={"button"} value={"Stock"}/>
                            </Link>
                            <Link to={'/about'}>
                                <input type={"button"} value={"About"}/>
                            </Link>
                            <Link to={'/contact'}>
                                <input type={"button"} value={"Contact"}/>
                            </Link>
                            <Link to={'/accounts'}>
                                <input type={"button"} value={"Accounts"}/>
                            </Link>
                        </form>
                    </PageContentColumn>
                </PageContentRow>
            </PageContent>
        </Page>
    )

}
