import React, {useEffect, useImperativeHandle, useRef, useState} from "react";

import './CustomFileInput.css';

const CustomFileInput = React.forwardRef((props, ref) => {

    const inputRef = useRef();
    const mutableCounter = useRef(0);
    const [changes, setChanges] = useState(0);
    const [files, setFiles] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [fileSize, setFileSize] = useState(0);
    const [deletedFiles, setDeletedFiles] = useState([]);

    const logChange = () => {
        mutableCounter.current++;
        setChanges(mutableCounter.current);
    }

    const handleChange = e => {
        if (props['maxSize'] && typeof props['maxSize'] === 'number') {
            const maxSize = props['maxSize'];
            let totalFileSize = 0;
            const tooBigIndex = Array.from(e.target.files).findLastIndex(file => {
                if (file.size <= maxSize) {
                    totalFileSize += file.size;
                    return false;
                }
                return true;
            });
            setFileSize(totalFileSize);
            if (tooBigIndex >= 0) {
                let unit = "GB";
                if (maxSize < 1073741824) {
                    unit = "MB";
                    if (maxSize < 1048576) {
                        unit = "KB";
                        if (maxSize < 1024) {
                            unit = " bytes";
                        }
                    }
                }
                let maxFileSize;
                switch (unit) {
                    case "GB":
                        maxFileSize = (Math.round(maxSize / 1073741824)) + unit;
                        break;
                    case "MB":
                        maxFileSize = (Math.round(maxSize / 1048576)) + unit;
                        break;
                    case "KB":
                        maxFileSize = (Math.round(maxSize / 1024)) + unit;
                        break;
                    default:
                        maxFileSize = maxSize + unit;
                }
                window.alert(`This file is too big. Please upload a file less than ${maxFileSize}`);
                return;
            }
        }
        logChange();
    }

    const handleClick = e => {
        if (!!props['onClick'] && typeof props['onClick'] === "function") {
            props['onClick'](e);
        }
        inputRef?.current?.click();
    }

    const handleFilesDeleted = () => {
        setDeletedFiles([]);
    }

    const handlePlaceholderClick = fileIndex => {
        if (window.confirm("Are you sure you want to remove this photo?")) {
            removeFile(fileIndex);
        }
    }

    const addFile = (file, fileName = "") => {
        files.unshift(file);
        fileNames.unshift(fileName);
        if (!!props['onChange'] && typeof props['onChange'] === 'function') {
            props['onChange']({
                target: ref.current,
                nativeTarget: inputRef.current,
                value: files
            });
        }
        logChange();
    }

    const removeFile = (index) => {
        const temp = Array.from(files);
        const temp2 = Array.from(fileNames);
        if (index < 0 || index > temp.length) return;
        temp.splice(index, 1);
        const fileName = temp2.splice(index, 1)[0];
        setFiles(temp);
        setFileNames(temp2);
        const deletedTemp = Array.from(deletedFiles);
        if (!deletedTemp.includes(fileName)) {
            deletedTemp.push(fileName);
            setDeletedFiles(deletedTemp.filter(fileName => !!fileName));
        }
        if (!!props['onChange'] && typeof props['onChange'] === 'function') {
            props['onChange']({
                target: ref.current,
                nativeTarget: inputRef.current,
                value: Array.from(temp)
            });
        }
        logChange();
    }

    useImperativeHandle(ref, () => ({
        "addFile": addFile,
        "deletedFiles": deletedFiles,
        "filesDeleted": handleFilesDeleted,
        "fileSize": fileSize,
        "length": files.length,
        "removeFile": removeFile,
        "reset": () => {
            setFiles([]);
            setFileNames([]);
            setFileSize(0);
            setDeletedFiles([]);
            logChange();
        },
        "totalLength": files.length + deletedFiles.length,
        "value": Array.from(files)
    }));

    useEffect(() => {

        let mounted = true;

        let addedFiles = 0;

        for (let file of inputRef.current?.files) {
            let reader = new FileReader();
            reader.addEventListener("load", e => {
                const result = e.target.result;
                if (mounted && !files.includes(result)) {
                    addFile(result);
                    addedFiles++;
                    if (!!inputRef.current && addedFiles === inputRef.current?.files.length) {
                        inputRef.current.value = "";
                    }
                }
            });
            if (!!file.type && file.type.startsWith("image/")) {
                reader.readAsDataURL(file);
            }
        }

        return () => {
            mounted = false;
        }

    }, [changes]);

    return (
        <div className={"custom-file-input"}>
            {
                !!props['showPlaceholders'] && (
                    <div className={"image-placeholders"}>
                        {files.map((file, fileIndex) => {

                            return (
                                <div key={`image-placeholder-${fileIndex}`} className={"image-placeholder"}
                                     onClick={e => handlePlaceholderClick(fileIndex)}>
                                    <img src={file} alt={`New stock image ${fileIndex}`}
                                         onLoad={e => {
                                             const elem = e.target;
                                             const [imgHeight, imgWidth] = [elem.naturalHeight, elem.naturalWidth];
                                             if (imgWidth < imgHeight && elem.classList.contains("landscape") && imgWidth !== 0) {
                                                 elem.classList.remove("landscape");
                                                 elem.style.transform = `translate3d(0, 0, 0) scale(${Math.round((imgHeight / imgWidth) * 100) / 100})`;
                                             } else if (imgHeight !== 0) {
                                                 elem.classList.add("landscape");
                                                 elem.style.transform = `translate3d(0, 0, 0) scale(${Math.round((imgWidth / imgHeight) * 100) / 100}`;
                                             }
                                         }}/>
                                </div>
                            );
                        })}
                        <div className={"image-placeholder"} onClick={() => inputRef?.current?.click()}/>
                    </div>
                )
            }
            <input type={"hidden"} value={files.join('|')} name={props['name']}/>
            <input type={"hidden"} value={deletedFiles.join('|')} name={props['name'] + "_deleted"}/>
            <input ref={inputRef} type={"file"} accept={props['accept']} multiple={!!props['multiple']}
                   onChange={handleChange}/>
            <input type={"button"} value={props['label'] ?? "Select files"} onClick={handleClick}
                   disabled={!!props['showPlaceholders'] || (!props['multiple'] && inputRef.current.files.length > 0)}/>
        </div>
    )

});

export default CustomFileInput;
