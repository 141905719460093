import React from "react";

import './Reviews.css';
import Page from "../../Components/Page/Page";
import PageContent from "../../Components/PageContent/PageContent";
import PageContentRow from "../../Components/PageContentRow/PageContentRow";
import ReviewsTable from "../../Components/ReviewsTable/ReviewsTable";
import PageContentColumn from "../../Components/PageContentColumn/PageContentColumn";
import {Link} from "react-router-dom";

export default function Reviews(props) {

    return (
        <Page>
            <PageContent>
                <PageContentRow>
                    <PageContentColumn>
                        <h2>Manage reviews on Legacy Cars.</h2>
                        <p className={"center"}>Approve and remove reviews on Legacy Cars.</p>
                    </PageContentColumn>
                </PageContentRow>
                <PageContentRow>
                    <PageContentColumn>
                        <p><Link to={"/about"}>&lsaquo; About</Link></p>
                        <h3>All reviews.</h3>
                        <p>Make reviews public or remove them instead.</p>
                        <ReviewsTable/>
                    </PageContentColumn>
                </PageContentRow>
            </PageContent>
        </Page>
    )

}
