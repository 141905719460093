import React from 'react';

import './LoadingIcon.css';
import classNames from "classnames";

export default function LoadingIcon(props) {

    const cx = classNames({
        "loading-icon": true,
        "inline": !!props['inline'],
        "fullscreen": !!props['fullscreen']
    });

    return (
        <div className={cx}>
            <div className={"loading-icon-shape-container"}>
                <div className={"loading-icon-shape"}/>
                <div className={"loading-icon-shape"}/>
            </div>
            <p>{!!props['label'] ? props['label'] : "Loading..."}</p>
            {
                !!props['progress'] && (
                    <div className={"loading-icon-progress-bar"}>
                        <div className={"loading-icon-progress-bar-indicator"} style={{width: `${props['progress']}%`}}/>
                    </div>
                )
            }
        </div>
    )
}
