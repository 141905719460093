import React, {useEffect, useState} from 'react';
import {Cookies, useCookies} from "react-cookie";
import {Navigate} from "react-router-dom";

const cookies = new Cookies();

const setUser = cookies.get("lc-user") ?? JSON.parse("{}");

const defaultValue = {
    loggedIn: false,
    auth: "",
    username: !!setUser['username'] ? setUser['username'] : ""
}

export const UserContext = React.createContext(defaultValue);

export function User(props) {

    const [cookies, setCookie] = useCookies(["lc-user"]);

    const loggedInState = useState(cookies['lc-user']?.loggedIn);
    const authState = useState(cookies['lc-user']?.auth);
    const usernameState = useState(cookies['lc-user']?.username);

    const value = {
        loggedIn: loggedInState,
        auth: authState,
        username: usernameState
    }

    const [loggedIn, setLoggedIn] = loggedInState;
    const [auth, setAuth] = authState;
    const [username, setUsername] = usernameState;

    useEffect(() => {

        setCookie("lc-user", JSON.stringify({
            auth: auth,
            loggedIn: loggedIn,
            username: username
        }), {
            maxAge: 10800,
            path: "/",
            secure: true
        });

    }, [loggedIn, auth, username]);

    return (
        <UserContext.Provider value={value}>
            {!loggedIn && !['/', '/login', '/logout'].includes(window.location.pathname) && <Navigate to={'/logout'}/>}
            {props.children}
        </UserContext.Provider>
    )
}
