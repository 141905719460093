import React from "react";

import './PageContentRow.css';
import classNames from "classnames";

export default function PageContentRow(props) {

    const cx = classNames({
        "page-content-row": true,
        "no-border": props['border'] !== undefined && !props['border'],
        ...(!!props['className'] ? {
            [props['className']]: props['className']
        } : {})
    });

    return (
        <div id={props['id']} className={cx} style={props['style']}>
            {props.children}
        </div>
    )

}
