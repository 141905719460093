import React, {useContext, useRef, useState} from "react";


import './Stock.css';
import Page from "../../Components/Page/Page";
import PageContent from "../../Components/PageContent/PageContent";
import PageContentRow from "../../Components/PageContentRow/PageContentRow";
import PageContentColumn from "../../Components/PageContentColumn/PageContentColumn";
import {Link} from "react-router-dom";
import StockTable from "../../Components/StockTable/StockTable";
import EditStockForm from "../../Components/EditStockForm/EditStockForm";
import axios from "axios";
import {UserContext} from "../../user";
import {Buffer} from "buffer";

export default function Stock(props) {

    const [showCreateNewListingForm, setShowCreateNewListingForm] = useState(false);

    const user = useContext(UserContext);

    const formRef = useRef();
    const tableRef = useRef();

    const handleSubmit = e => {
        e.preventDefault();
        const data = {};
        const formData = new FormData(e.target);
        formData.forEach((value, key) => {
            data[key] = value;
        });
        for (let key of ["year", "mileage", "engine_capacity"]) {
            if (key in data) {
                try {
                    data[key] = parseInt(data[key]);
                } catch (er) {
                    console.warn(er);
                }
            }
        }
        if ("price" in data) {
            try {
                data["price"] = parseFloat(data["price"]);
            } catch (er) {
                console.warn(er);
            }
        }
        let images = [];
        if ("images" in data && data['images'] !== "") {
            images = data['images'].split('|');
            delete data['images'];
        }
        data['is_sold'] = Object.keys(data).includes("is_sold");
        const formComponent = formRef.current;
        formComponent?.setResponse("");
        formComponent?.setError("");
        formComponent?.setSaving(1);
        let saveProgress = 0;
        axios.post(`${process.env.REACT_APP_API_URL}/v1/admin/stock`, data, {
            headers: {
                Authorization: `Basic ${user['auth'][0]}`
            }
        })
            .then(response => {
                if (images.length > 0) {
                    saveProgress = 10;
                    formComponent?.setSaving(saveProgress);
                } else {
                    saveProgress = 100;
                    formComponent?.setSaving(saveProgress);
                }
                switch (response.status) {
                    case 200:
                    case 201:
                        formComponent?.setResponse("Changes saved successfully");
                        let uploadTotal = 0;
                        let uploadProgress = [];
                        for (let i = 0; i < images.length; i++) {
                            let img = images[i];
                            uploadTotal += Buffer.from(img.substring(img.indexOf(',') + 1)).length;
                            axios.post(`${process.env.REACT_APP_API_URL}/v1/admin/media/stock/${response.data.data.stock_id}`, img, {
                                headers: {
                                    Authorization: `Basic ${user['auth'][0]}`
                                },
                                onUploadProgress: progressEvent => {
                                    uploadProgress[i] = progressEvent.loaded;
                                    saveProgress = (uploadProgress.reduce((partialSum, a) => partialSum + a, 0) / uploadTotal) * 90 + 10;
                                    formComponent?.setSaving(saveProgress);
                                }
                            })
                                .then(response => {
                                    if (saveProgress >= 100) {
                                        formComponent?.reset();
                                        setShowCreateNewListingForm(false);
                                        if (!!tableRef?.current) {
                                            tableRef?.current.refresh();
                                        }
                                    }
                                })
                                .catch(error => {
                                    formComponent?.setError(error.message);
                                })
                        }
                        break;
                    default:
                        formComponent?.setError(response.message ?? "Could not save changes. Please try again later");
                }
            })
            .catch(error => {
                formComponent?.setError(error.message ?? `Could not save changes. Please try again later`);
                formComponent?.setSaving(0);
            })
    }

    return (
        <Page>
            <PageContent>
                <PageContentRow>
                    <PageContentColumn>
                        <h2>Manage stock on Legacy Cars.</h2>
                        <p className={"center"}>Show your customers what you can offer.</p>
                    </PageContentColumn>
                </PageContentRow>
                <PageContentRow>
                    <PageContentColumn>
                        <p><Link to={"/"}>&lsaquo; Dashboard</Link></p>
                        <h3>Create a new listing.</h3>
                        <p>Add something new to Legacy Cars.</p>
                        <EditStockForm ref={formRef} collapse={!showCreateNewListingForm}
                                       onCancel={() => setShowCreateNewListingForm(false)} onSubmit={handleSubmit}/>
                        <form>
                            <input type={"button"} value={"+ Create Listing"}
                                   onClick={() => setShowCreateNewListingForm(true)}
                                   disabled={showCreateNewListingForm}/>
                        </form>
                    </PageContentColumn>
                </PageContentRow>
                <PageContentRow>
                    <PageContentColumn>
                        <h3>Manage listings.</h3>
                        <p>Modify your current catalog.</p>
                        <StockTable ref={tableRef}/>
                    </PageContentColumn>
                </PageContentRow>
            </PageContent>
        </Page>
    )

}
