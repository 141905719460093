import React, {useContext} from "react";

import {UserContext} from "../../user";

import Logo from '../../Images/Branding/White-Logo-Transparent.png';

import './Footer.css';
import {Link} from "react-router-dom";

export default function Footer(props) {

    const user = useContext(UserContext);

    const [loggedIn, setLoggedIn] = user['loggedIn'];

    return (
        <div className={"footer"}>
            <div className={"footer-content"}>
                <h4>The simple, trusted dealership.</h4>
                <p><Link to={`${process.env.REACT_APP_MAIN_SITE_URL}/contact`}>Get in touch</Link> | <Link
                    to={`/${loggedIn ? "logout" : "login"}`}>Sign {loggedIn ? "out" : "in"}</Link></p>
                <p><Link to={`${process.env.REACT_APP_MAIN_SITE_URL}/about/terms-and-conditions`}>Terms and
                    conditions</Link></p>
            </div>
            <img className={"footer-logo"} src={Logo} alt={"Legacy Cars"}/>
        </div>
    )

}
