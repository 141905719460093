import React from "react";

import './PageContent.css';
import classNames from "classnames";

export default function PageContent(props){

    const cx = classNames({
        "page-content": true,
        "ignore-header": !!props['ignoreHeader']
    });

    return (
        <div className={cx}>
            {props.children}
        </div>
    )
}
