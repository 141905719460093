import React, {useContext, useState} from 'react';

import './Login.css';
import Page from "../../Components/Page/Page";
import PageContentColumn from "../../Components/PageContentColumn/PageContentColumn";
import PageContentRow from "../../Components/PageContentRow/PageContentRow";
import PageContent from "../../Components/PageContent/PageContent";

import {UserContext} from "../../user";
import classNames from "classnames";

import axios from "axios";
import {Navigate} from "react-router-dom";

export default function Login(props) {

    const user = useContext(UserContext);

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [errorResponse, setErrorResponse] = useState();

    const [loggedIn, setLoggedIn] = user['loggedIn'];

    const logIn = e => {
        e.preventDefault();
        setErrorResponse(null);
        axios.post(`${process.env.REACT_APP_API_URL}/v1/auth`, {
            username: username,
            password: password
        }, {
            auth: {
                username: username,
                password: password
            }
        })
            .then(response => {
                switch (response.status) {
                    case 200:
                        const userData = response.data.data;
                        const [userUsername, setUserUsername] = user['username'];
                        const [userAuth, setUserAuth] = user['auth'];
                        setLoggedIn(true);
                        setUserUsername(userData['username']);
                        setUserAuth(btoa([userData['username'], password].join(':')));
                        break;
                    default:
                        setErrorResponse("Legacy Cars is unavailable right now. Please try again later");
                }
            })
            .catch(error => {
                const response = error.response;
                switch (response?.status) {
                    case 401:
                        setErrorResponse("Username or password is incorrect");
                        break;
                    default:
                        setErrorResponse("Legacy Cars is unavailable right now. Please try again later");
                }
            });
    }

    const responseCx = classNames({
        response: !errorResponse,
        error: !!errorResponse
    })

    return (
        <>
            {
                loggedIn && <Navigate to={'/'}/>
            }
            <Page>
                <PageContent>
                    <PageContentRow>
                        <PageContentColumn>
                            <form onSubmit={logIn}>
                                <h3>Sign in to Legacy Cars</h3>
                                <p>This portal is intended for use by authorised users of Legacy cars only.</p>
                                <p className={responseCx}>{errorResponse}</p>
                                <input type={"text"} placeholder={"Username"}
                                       onChange={e => setUsername(e.target.value)}/>
                                <input type={"password"} placeholder={"Password"}
                                       onChange={e => setPassword(e.target.value)}/>
                                <input type={"submit"} value={"Sign in"}/>
                            </form>
                        </PageContentColumn>
                    </PageContentRow>
                </PageContent>
            </Page>
        </>
    )

}
