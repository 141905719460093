import './Fonts/webfonts.css';
import './App.css';
import Header from "./Components/Header/Header";

import {Appearance} from "./appearance";
import {User} from "./user";
import {CookiesProvider} from "react-cookie";

import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";

import PublicPrivateRoute from "./Components/PublicPrivateRoute/PublicPrivateRoute";
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";

import Login from './Pages/Login/Login';
import Dashboard from "./Pages/Dashboard/Dashboard";
import Contact from "./Pages/Contact/Contact";
import Logout from "./Pages/Logout/Logout";
import Stock from "./Pages/Stock/Stock";
import Accounts from "./Pages/Accounts/Accounts";
import About from "./Pages/About/About";
import Reviews from "./Pages/Reviews/Reviews";

function App() {
    return (
        <CookiesProvider>
            <Appearance>
                <Router>
                    <User>
                        <div className="App">
                            <Header/>
                            <Routes>
                                <Route path={'*'} element={<Navigate to={'/'}/>}/>
                                <Route exact path={'/'} element={<PrivateRoute/>}>
                                    <Route exact path={'/'} element={<Dashboard/>}/>
                                </Route>
                                <Route exact path={'/accounts'} element={<PrivateRoute/>}>
                                    <Route exact path={'/accounts'} element={<Accounts/>}/>
                                </Route>
                                <Route exact path={'/contact'} element={<PrivateRoute/>}>
                                    <Route exact path={'/contact'} element={<Contact/>}/>
                                </Route>
                                <Route exact path={'/stock'} element={<PrivateRoute/>}>
                                    <Route exact path={'/stock'} element={<Stock/>}/>
                                </Route>
                                <Route exact path={'/about'} element={<PrivateRoute/>}>
                                    <Route exact path={'/about'} element={<About/>}/>
                                    <Route exact path={'/about/reviews'} element={<Reviews/>}/>
                                </Route>
                                <Route exact path={'/logout'} element={<PrivateRoute/>}>
                                    <Route exact path={'/logout'} element={<Logout/>}/>
                                </Route>
                                <Route exact path={'/login'} element={<PublicPrivateRoute/>}>
                                    <Route exact path={'/login'} element={<Login/>}/>
                                </Route>
                            </Routes>
                        </div>
                    </User>
                </Router>
            </Appearance>
        </CookiesProvider>
    );
}

export default App;
