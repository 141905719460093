import React, {useState} from "react";

import './CustomSelect.css';

export default function CustomSelect(props) {

    const reservedProps = ["className", "children", " placeholder", "defaultValue"];

    const [newValue, setNewValue] = useState("");
    const [value, setValue] = useState("");
    const [disablePrompt, setDisablePrompt] = useState(false);

    const spreadableProps = {...props};
    for (let key of reservedProps) {
        if (Object.keys(spreadableProps).includes(key)) {
            delete spreadableProps[key];
        }
    }

    const handleChange = e => {
        setDisablePrompt(true);
        setValue(e.target.value);
        const tempEvent = {...e};
        if (e.target.value === "cs-add-new") {
            tempEvent.target.value = "";
        }
        if (props['onChange'] && typeof props['onChange'] === 'function') {
            props['onChange'](tempEvent);
        }
    }

    return (
        <>
            <select className={"custom-select"}
                    value={newValue !== "" ? newValue : (props['defaultValue'] !== "" ? props['defaultValue'] : value)} {...spreadableProps}
                    onChange={handleChange}>
                {props['placeholder'] && <option value={""} disabled={disablePrompt}>{props['placeholder']}</option>}
                <optgroup label={"New"}>
                    <option value={"cs-add-new"}>+ Add new</option>
                    {newValue && <option value={newValue}>{newValue}</option>}
                </optgroup>
                {props.children && props.children.length > 0 && <optgroup label={"Current"}>
                    {props.children}
                </optgroup>}
            </select>
            {(value === "cs-add-new" || !!newValue) && (
                <input type={"text"} value={newValue}
                       placeholder={`New ${props['placeholder']?.toLowerCase() ?? "value"}`} onChange={e => {
                    const tempEvent = {...e};
                    if (e.target.value === "") {
                        setValue("cs-add-new");
                        tempEvent.target.value = "cs-add-new";
                    } else {
                        setValue(e.target.value);
                    }
                    setNewValue(e.target.value === "cs-add-new" ? "" : e.target.value);
                    handleChange(tempEvent);
                }} required/>
            )}
        </>
    )

}
