import React, {useCallback, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";

import './StockTable.css';
import axios from "axios";
import LoadingIcon from "../LoadingIcon/LoadingIcon";
import EditStockForm from "../EditStockForm/EditStockForm";
import {UserContext} from "../../user";
import {Buffer} from "buffer";

const StockTable = React.forwardRef((props, ref) => {

    const [loading, setLoading] = useState(true);
    const [maxLoadingReached, setMaxLoadingReached] = useState(false);

    const [error, setError] = useState("");

    const [stock, setStock] = useState([]);

    const [currentlyEditingID, setCurrentlyEditingID] = useState(null);

    const [search, setSearch] = useState("");
    const [stockLoadBatchSize, setStockLoadBatchSize] = useState(10);
    const [stockLimitRange, setStockLimitRange] = useState([0, stockLoadBatchSize]);

    const [_refresh, _setRefresh] = useState(0);

    const user = useContext(UserContext);

    const formRef = useRef();

    const refresh = () => {
        _setRefresh(_refresh + 1);
    }

    useImperativeHandle(ref, () => ({
        refresh: refresh
    }));

    const getStockByID = useCallback(id => {
        return stock.findIndex(stockItem => {
            try {
                return parseInt(stockItem.stock_id) === parseInt(id);
            } catch (er) {
                return false;
            }
        });
    }, [stock])

    const loadMoreStock = e => {
        e?.preventDefault();
        stockLimitRange[1] = stockLoadBatchSize;
        stockLimitRange[0] = stock.length;
        axios.get(`${process.env.REACT_APP_API_URL}/v1/stock/?offset=${stockLimitRange[0]}&limit=${stockLimitRange[1]}${(search !== "" ? `&search=${search}` : `&order=stock_id%20DESC`)}`)
            .then(response => {
                const data = response.data?.data;
                switch (response.status) {
                    case 200:
                    case 204:
                        setStock(data && data.length > 0 ? [...stock, ...data] : [...stock]);
                        if (response.status === 200) {
                            break;
                        }
                    default:
                        setMaxLoadingReached(true);
                }
                setLoading(false);
            })
            .catch(error => {
                setError(error.response?.message ?? "Could not load stock information");
                setMaxLoadingReached(true);
            });
    }

    const handleSubmit = e => {
        e.preventDefault();
        const data = {};
        const formData = new FormData(e.target);
        formData.forEach((value, key) => {
            data[key] = value;
        });
        for (let key of ["year", "mileage", "engine_capacity", "stock_id"]) {
            if (key in data) {
                try {
                    data[key] = parseInt(data[key]);
                } catch (er) {
                    console.warn(er);
                }
            }
        }
        if ("price" in data) {
            try {
                data["price"] = parseFloat(data["price"]);
            } catch (er) {
                console.warn(er);
            }
        }
        data['is_sold'] = Object.keys(data).includes("is_sold");
        let images = [];
        if ("images" in data && data['images'] !== "") {
            images = data['images'].split('|');
            delete data['images'];
        }
        let deletedImages = [];
        if ("images_deleted" in data && data['images_deleted'] !== "") {
            deletedImages = data['images_deleted'].split('|');
            delete data['images_deleted'];
        }
        const formComponent = formRef.current;
        formComponent?.setResponse("");
        formComponent?.setError("");
        formComponent?.setSaving(1);
        let saveProgress = 0;
        axios.patch(`${process.env.REACT_APP_API_URL}/v1/admin/stock`, data, {
            headers: {
                Authorization: `Basic ${user['auth'][0]}`
            }
        })
            .then(response => {
                let deletedImageCount = 0;
                let uploadedImageCount = 0;
                if (deletedImages.length > 0) {
                    saveProgress = 1;
                    formComponent?.setSaving(saveProgress);

                    for (let i = 0; i < deletedImages.length; i++) {
                        axios.delete(`${process.env.REACT_APP_API_URL}/v1/admin/media/stock/${deletedImages[i]}`, {
                            headers: {
                                Authorization: `Basic ${user['auth'][0]}`
                            },
                            onUploadProgress: progressEvent => {
                                saveProgress = ((progressEvent.loaded / progressEvent.total) * (images.length > 0 ? 10 : 100) * (i / deletedImages.length)) + 1;
                                formComponent?.setSaving(saveProgress);
                            }
                        })
                            .then(response => {
                                if (response.status === 200) {
                                    deletedImageCount++;
                                    const stockItemID = data.stock_id;
                                    if (stockItemID) {
                                        const stockItem = stock[getStockByID(stockItemID)];
                                        if (stockItem && stockItem.media.length > 0) {
                                            const stockItemMediaIndex = stockItem.media?.findIndex(media => media.media_id === deletedImages[i]);
                                            if (stockItemMediaIndex > -1) {
                                                stockItem.media.splice(stockItemMediaIndex, 1);
                                            }
                                        }
                                    }
                                }
                                if (images.length + deletedImages.length === deletedImageCount) {
                                    formComponent?.reset(true);
                                    setCurrentlyEditingID(null);
                                }
                            })
                    }
                }
                if (images.length > 0) {
                    saveProgress = 10;
                    formComponent?.setSaving(saveProgress);
                } else {
                    saveProgress = 100;
                    formComponent?.setSaving(saveProgress);
                }
                switch (response.status) {
                    case 200:
                    case 201:
                        const stockItemIndex = getStockByID(response.data.data.stock_id);
                        const stockItem = stock[stockItemIndex];
                        if (stockItem) {
                            const temp = [...stock];
                            temp[stockItemIndex] = response.data.data;
                            setStock(temp);
                        }
                        formComponent?.setResponse("Changes saved successfully");
                        if (images.length + deletedImages.length === 0) {
                            formComponent?.reset(true);
                            setCurrentlyEditingID(null);
                            break;
                        }
                        let uploadTotal = 0;
                        let uploadProgress = [];
                        for (let i = 0; i < images.length; i++) {
                            let img = images[i];
                            uploadTotal += Buffer.from(img.substring(img.indexOf(',') + 1)).length;
                            axios.post(`${process.env.REACT_APP_API_URL}/v1/admin/media/stock/${response.data.data.stock_id}`, img, {
                                headers: {
                                    Authorization: `Basic ${user['auth'][0]}`
                                },
                                onUploadProgress: progressEvent => {
                                    uploadProgress[i] = progressEvent.loaded;
                                    saveProgress = (uploadProgress.reduce((partialSum, a) => partialSum + a, 0) / uploadTotal) * 90 + 10;
                                    formComponent?.setSaving(saveProgress);
                                }
                            })
                                .then(response => {
                                    if (response.status === 201) {
                                        uploadedImageCount++;
                                    }
                                    if (saveProgress >= 100) {
                                        formComponent?.reset();
                                        refresh();
                                    }
                                    if (images.length + deletedImages.length === uploadedImageCount + deletedImageCount) {
                                        formComponent?.reset(true);
                                        setCurrentlyEditingID(null);
                                    }
                                })
                                .catch(error => {
                                    formComponent?.setError(error.message);
                                })
                        }
                        break;
                    default:
                        formComponent?.setError(response.message ?? "Could not save changes. Please try again later");
                }
            })
            .catch(error => {
                formComponent?.setError(error.message ?? `Could not save changes. Please try again later`);
                formComponent?.setSaving(0);
            })
    }

    const handleDelete = () => {
        const formComponent = formRef.current;
        const temp = Array.from(stock);
        for (let i = 0; i < temp.length; i++) {
            let stockData = temp[i];
            if (stockData.id === currentlyEditingID) {
                temp.splice(i, 1);
                break;
            }
        }
        setStock(temp);
        formComponent?.reset(true);
        setCurrentlyEditingID(null);
    }

    useEffect(() => {

        setError("");
        setLoading(true);
        setStockLimitRange([0, 10]);

        axios.get(`${process.env.REACT_APP_API_URL}/v1/stock/?offset=${stockLimitRange[0]}&limit=${stockLimitRange[1]}${(search !== "" ? `&search=${search}` : `&order=id%20DESC`)}`)
            .then(response => {
                const data = response.data?.data;
                switch (response.status) {
                    case 200:
                    case 204:
                        setStock(data ?? []);
                        if (response.status === 200) {
                            break;
                        }
                    default:
                        setMaxLoadingReached(true);
                }
                setLoading(false);
            })
            .catch(error => {
                setError(error.response?.message ?? "Could not load stock information");
            });

    }, [search, _refresh]);

    return (
        <>
            <form className={"stock-table-search-form"}>
                <input type={"text"} name={"search"} placeholder={"Search stock"} value={search}
                       onChange={e => setSearch(e.target.value)}/>
            </form>
            <div className={"stock-table-area"}>
                <table className={"stock-table"}>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Status</th>
                        <th>Make</th>
                        <th>Model</th>
                        <th>Trim</th>
                        <th>Engine Cylinder Capacity (cc)</th>
                        <th>Year</th>
                        <th>Colour</th>
                        <th>List Price</th>
                        <th>Date Listed</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {stock.length === 0 && (
                        <tr>
                            <td>
                                <p>{!!search ? `No results for "${search}"` : "Nothing to show"}</p>
                            </td>
                        </tr>
                    )}
                    {stock.map((item, index) => {
                            return (
                                <React.Fragment key={`stock-table-row-fragment-${index}`}>
                                    <tr key={`stock-table-row-${index}`}
                                        className={![null, item.stock_id].includes(currentlyEditingID) ? "disabled" : ""}>
                                        <td className={"bold"}>{item.stock_id}</td>
                                        <td className={"bold"}>
                                            <mark
                                                className={item.is_sold ? "sold" : ""}>{item.is_sold ? "Sold" : "Listed"}</mark>
                                        </td>
                                        <td>{item.make}</td>
                                        <td>{item.model}</td>
                                        <td>{item.trim}</td>
                                        <td>{item.engine_capacity}</td>
                                        <td>{item.year}</td>
                                        <td>{item.color}</td>
                                        <td>&pound;{parseFloat(item.price)?.toFixed(2)}</td>
                                        <td>{new Date(Date.parse(item.time_added))?.toDateString()}</td>
                                        <td>
                                            <form><input type={"button"} value={"Edit"}
                                                         onClick={() => setCurrentlyEditingID(item.stock_id)}
                                                         disabled={!!currentlyEditingID}/></form>
                                        </td>
                                    </tr>
                                    {
                                        currentlyEditingID === item.stock_id && (
                                            <tr key={`stock-table-edit-form-row-${index}`} className={"edit-stock-form-row"}>
                                                <td colSpan={11}>
                                                    <EditStockForm ref={formRef} data={item} animate={true}
                                                                   onSubmit={handleSubmit}
                                                                   onCancel={() => {
                                                                       setCurrentlyEditingID(null)
                                                                   }}
                                                                   onDelete={handleDelete}/>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </React.Fragment>
                            )
                        }
                    )}
                    </tbody>
                </table>
                <p className={"error"}>{error}</p>
                {stock.length > 0 && (
                    <form className={"stock-table-load-more-form"} onSubmit={loadMoreStock}>
                        <input type={"submit"} value={maxLoadingReached ? "Showing All Results" : "Load more..."}
                               disabled={maxLoadingReached}/>
                    </form>
                )}
                {loading && <LoadingIcon/>}
            </div>
        </>
    )

});

export default StockTable;
