import React, {useContext, useEffect, useState} from 'react';

import './Contact.css';
import Page from "../../Components/Page/Page";
import PageContent from "../../Components/PageContent/PageContent";
import PageContentColumn from "../../Components/PageContentColumn/PageContentColumn";
import PageContentRow from "../../Components/PageContentRow/PageContentRow";
import axios from "axios";
import {UserContext} from "../../user";
import LoadingIcon from "../../Components/LoadingIcon/LoadingIcon";
import {Link} from "react-router-dom";

export default function Contact(props) {

    const user = useContext(UserContext);

    const [phoneLoading, setPhoneLoading] = useState(false);
    const [emailLoading, setEmailLoading] = useState(false);
    const [locationLoading, setLocationLoading] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState();
    const [phoneOpeningTimes, setPhoneOpeningTimes] = useState();
    const [whatsApp, setWhatsApp] = useState();
    const [emailAddress, setEmailAddress] = useState();
    const [emailReplyTime, setEmailReplyTime] = useState();
    const [address, setAddress] = useState();

    const [currentPhoneNumber, setCurrentPhoneNumber] = useState();
    const [currentPhoneOpeningTimes, setCurrentPhoneOpeningTimes] = useState();
    const [currentWhatsApp, setCurrentWhatsApp] = useState();
    const [currentEmailAddress, setCurrentEmailAddress] = useState();
    const [currentEmailReplyTime, setCurrentEmailReplyTime] = useState();
    const [currentAddress, setCurrentAddress] = useState();

    const [phoneError, setPhoneError] = useState();
    const [emailError, setEmailError] = useState();
    const [locationError, setLocationError] = useState();

    const [phoneResponse, setPhoneResponse] = useState();
    const [emailResponse, setEmailResponse] = useState();
    const [locationResponse, setLocationResponse] = useState();


    const savePhone = e => {
        e.preventDefault();
        setPhoneLoading(true);
        setPhoneError(null);
        setPhoneResponse(null);
        axios.patch(`${process.env.REACT_APP_API_URL}/v1/admin/contact`, {
            phone: phoneNumber ?? undefined,
            phone_open: phoneOpeningTimes ?? undefined,
            whatsapp: whatsApp ?? undefined
        }, {
            headers: {
                Authorization: `Basic ${user['auth'][0]}`
            }
        })
            .then(response => {
                setPhoneLoading(false);
                switch (response?.status) {
                    case 200:
                    case 201:
                        setPhoneResponse("Changes saved successfully");
                        if (!!phoneNumber) setCurrentPhoneNumber(phoneNumber);
                        if (!!phoneOpeningTimes) setCurrentPhoneOpeningTimes(phoneOpeningTimes);
                        break;
                    default:
                        setPhoneError("Could not save changes. Please try again later");
                }
            })
            .catch(error => {
                setPhoneError("Could not save changes. Please try again later");
                setPhoneLoading(false);
            });
    }

    const saveEmail = e => {
        e.preventDefault();
        setEmailLoading(true);
        setEmailError(null);
        setEmailResponse(null);
        axios.patch(`${process.env.REACT_APP_API_URL}/v1/admin/contact`, {
            email: emailAddress ?? undefined,
            email_reply_time: emailReplyTime ?? undefined
        }, {
            auth: {
                username: user['username'][0],
                password: user['password'][0]
            }
        })
            .then(response => {
                setEmailLoading(false);
                switch (response?.status) {
                    case 200:
                    case 201:
                        setEmailResponse("Changes saved successfully");
                        if (!!emailAddress) setCurrentEmailAddress(emailAddress);
                        if (!!emailReplyTime) setCurrentEmailReplyTime(emailReplyTime);
                        break;
                    default:
                        setEmailError("Could not save changes. Please try again later");
                }
            })
            .catch(error => {
                setEmailError("Could not save changes. Please try again later");
                setEmailLoading(false);
            });
    }

    const saveLocation = e => {
        e.preventDefault();
        setLocationLoading(true);
        setLocationError(null);
        setLocationResponse(null);
        axios.patch(`${process.env.REACT_APP_API_URL}/v1/admin/contact`, {
            address: address ?? undefined
        }, {
            headers: {
                Authorization: `Basic ${user['auth'][0]}`
            }
        })
            .then(response => {
                setLocationLoading(false);
                switch (response?.status) {
                    case 200:
                    case 201:
                        setLocationResponse("Changes saved successfully");
                        if (!!address) setCurrentAddress(address);
                        break;
                    default:
                        setLocationError("Could not save changes. Please try again later");
                }
            })
            .catch(error => {
                setLocationError("Could not save changes. Please try again later");
                setLocationLoading(false);
            });
    }


    useEffect(() => {

        setPhoneLoading(true);
        setEmailLoading(true);
        setLocationLoading(true);
        setPhoneError(null);
        setPhoneResponse(null);
        setEmailError(null);
        setEmailResponse(null);
        setLocationError(null);
        setLocationResponse(null);


        axios.get(`${process.env.REACT_APP_API_URL}/v1/admin/contact`, {
            headers: {
                Authorization: `Basic ${user['auth'][0]}`
            }
        })
            .then(response => {
                setPhoneLoading(false);
                setEmailLoading(false);
                setLocationLoading(false);
                switch (response.status) {
                    case 200:
                        const data = response.data.data;
                        setCurrentPhoneNumber(data['phone']);
                        setCurrentPhoneOpeningTimes(data['phone_open']);
                        setCurrentWhatsApp(data['whatsapp']);
                        setCurrentEmailAddress(data['email']);
                        setCurrentEmailReplyTime(data['email_reply_time']);
                        setCurrentAddress(data['address']);
                        setPhoneNumber(data['phone']);
                        setPhoneOpeningTimes(data['phone_open']);
                        setWhatsApp(data['whatsapp']);
                        setEmailAddress(data['email']);
                        setEmailReplyTime(data['email_reply_time']);
                        setAddress(data['address']);
                        break;
                    case 204:
                        break;
                    default:
                        setPhoneError("Could not retrieve contact information");
                        setEmailError("Could not retrieve contact information");
                        setLocationError("Could not retrieve location information");
                }
            })
            .catch(error => {
                setPhoneError("Could not retrieve contact information");
                setEmailError("Could not retrieve contact information");
                setLocationError("Could not retrieve location information");
            });
    }, []);

    return (
        <Page>
            <PageContent>
                <PageContentRow>
                    <PageContentColumn>
                        <h2>Manage contact information on Legacy Cars.</h2>
                        <p className={"center"}>Let customers know how to contact you and where to find you.</p>
                    </PageContentColumn>
                </PageContentRow>
                <PageContentRow>
                    <PageContentColumn>
                        <p><Link to={"/"}>&lsaquo; Dashboard</Link></p>
                        <h3>Provide your contact details.</h3>
                        <p>Contact details will be made public for customer use.</p>
                        <form className={"block"} onSubmit={savePhone}>
                            {phoneLoading && <LoadingIcon/>}
                            <h4>Phone line</h4>
                            <p className={"error"}>{phoneError}</p>
                            <p className={"response"}>{phoneResponse}</p>
                            <label htmlFor={"phone-number-input"}>What is your phone number?</label>
                            <input id={"phone-number-input"} name={"phone_number"} type={"text"}
                                   placeholder={"Phone number"} defaultValue={currentPhoneNumber} maxLength={24}
                                   onChange={e => setPhoneNumber(e.target.value)}/>
                            <label htmlFor={"phone-opening-times-input"}>When are you contactable by phone?</label>
                            <input id={"phone-opening-times-input"} name={"phone_opening_times"} type={"text"}
                                   placeholder={"Opening times"} defaultValue={currentPhoneOpeningTimes} maxLength={128}
                                   onChange={e => setPhoneOpeningTimes(e.target.value)}/>
                            <label htmlFor={"whatsapp-input"}>What is your WhatsApp phone number?</label>
                            <input id={"whatsapp-input"} name={"whatsapp"} type={"text"}
                                   placeholder={"Whatsapp phone number"} defaultValue={currentWhatsApp}
                                   onChange={e => setWhatsApp(e.target.value)} maxLength={24}/>
                            <input type={"submit"} value={"Save Changes"}
                                   disabled={phoneNumber === currentPhoneNumber && phoneOpeningTimes === currentPhoneOpeningTimes && whatsApp === currentWhatsApp}/>
                        </form>
                        <form className={"block"} onSubmit={saveEmail}>
                            {emailLoading && <LoadingIcon/>}
                            <h4>Email address</h4>
                            <p className={"error"}>{emailError}</p>
                            <p className={"response"}>{emailResponse}</p>
                            <label htmlFor={"email-input"}>What is your email address?</label>
                            <input id={"email-input"} name={"email"} type={"text"} placeholder={"Email address"}
                                   defaultValue={currentEmailAddress} maxLength={64}
                                   onChange={e => setEmailAddress(e.target.value)}/>
                            <label htmlFor={"email-reply-time-input"}>When are you contactable by phone?</label>
                            <input id={"email-reply-time-input"} name={"email_reply_time"} type={"text"}
                                   placeholder={"Email reply time"} defaultValue={currentEmailReplyTime} maxLength={128}
                                   onChange={e => setEmailReplyTime(e.target.value)}/>
                            <input type={"submit"} value={"Save Changes"}
                                   disabled={emailAddress === currentEmailAddress && emailReplyTime === currentEmailReplyTime}/>
                        </form>
                    </PageContentColumn>
                </PageContentRow>
                <PageContentRow>
                    <PageContentColumn>
                        <h3>Provide location information.</h3>
                        <p>Let your customers know how to find you.</p>
                        <form className={"block"} onSubmit={saveLocation}>
                            {locationLoading && <LoadingIcon/>}
                            <h4>Location</h4>
                            <p className={"error"}>{locationError}</p>
                            <p className={"response"}>{locationResponse}</p>
                            <label htmlFor={"address-input"}>Where are you located?</label>
                            <input type={"text"} placeholder={"Address"} id={"address-input"} name={"address"}
                                   defaultValue={currentAddress} maxLength={128}
                                   onChange={e => setAddress(e.target.value)}/>
                            <input type={"submit"} value={"Save Changes"} disabled={address === currentAddress}/>
                        </form>
                    </PageContentColumn>
                </PageContentRow>
            </PageContent>
        </Page>
    )

}
