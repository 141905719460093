import React, {useContext, useEffect, useRef, useState} from 'react';

import './Header.css';

import Logo from '../../Images/Branding/Black-Logo-Transparent.png';
import {Link, useLocation} from "react-router-dom";
import {AppearanceContext} from "../../appearance";
import classNames from "classnames";

import {UserContext} from "../../user";
import HamburgerIcon from "../HamburgerIcon/HamburgerIcon";

export default function Header(props) {

    const location = useLocation();
    const path = location.pathname;

    const [fill, setFill] = useState((props['defaultStyle']?.toLowerCase() !== 'split'));
    const [collapse, setCollapse] = useState(true);
    const [hideWhenCollapsed, _setHideWhenCollapsed] = useState(true);

    const appearance = useContext(AppearanceContext);
    const user = useContext(UserContext);

    const [invertHeader, setInvertHeader] = appearance.invertHeader;

    const cx = classNames({
        'site-header': true,
        'invert': !fill && hideWhenCollapsed && (invertHeader || collapse),
        'fill': fill || collapse,
        'collapse': collapse,
        'hide-on-collapse': hideWhenCollapsed && collapse
    })

    const containerRef = useRef();
    const hamburgerIconRef = useRef();

    const setHideWhenCollapsed = state => {
        if (!!hamburgerIconRef.current) {
            hamburgerIconRef.current.setClosed(!state);
        }
        _setHideWhenCollapsed(state);
    }

    const handleWindowScroll = e => {
        setFill(!!window.scrollY && !!containerRef.current && window.scrollY > containerRef.current.clientHeight);
    }

    const handleWindowResize = e => {
        setCollapse(window.innerWidth < 500);
    }

    useEffect(() => {

        handleWindowScroll();
        handleWindowResize();

        window.addEventListener('scroll', handleWindowScroll);
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('scroll', handleWindowScroll);
            window.removeEventListener('resize', handleWindowResize);
        }

    }, []);

    return (
        <div className={cx}>
            <span className={"horizontal"} ref={containerRef}>
                {collapse && user.loggedIn[0] && <HamburgerIcon ref={hamburgerIconRef}
                                                                invert={!(!fill && hideWhenCollapsed && (invertHeader || collapse))}
                                                                onClick={() => setHideWhenCollapsed(!hideWhenCollapsed)}/>}
                <Link to={`${process.env.REACT_APP_MAIN_SITE_URL}`}>
                    <img className={"site-header-logo"} src={Logo} alt={"Legacy Cars"}/>
                </Link>
            </span>
            <header className={"site-header-menu"}>
                {user.loggedIn[0] && (
                    <>
                        <Link to={'/'} onClick={() => setHideWhenCollapsed(true)}>
                            <p>Dashboard</p>
                        </Link>
                        <Link to={'/stock'} onClick={() => setHideWhenCollapsed(true)}>
                            <p>Stock</p>
                        </Link>
                        <Link to={'/about'} onClick={() => setHideWhenCollapsed(true)}>
                            <p>About</p>
                        </Link>
                        <Link to={'/contact'} onClick={() => setHideWhenCollapsed(true)}>
                            <p>Contact</p>
                        </Link>
                        <Link to={'/accounts'} onClick={() => setHideWhenCollapsed(true)}>
                            <p>Accounts</p>
                        </Link>
                    </>
                )}
            </header>
        </div>
    )
}
