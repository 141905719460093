import React, {useState} from "react";

import './Checkbox.css';
import classNames from "classnames";

const Checkbox = React.forwardRef((props, ref) => {

    const [checked, setChecked] = useState(!!props['defaultChecked'] ?? false);

    const handleChange = e => {
        setChecked(e.target.checked);
        if (!!props['onChange'] && typeof props['onChange'] === 'function') {
            props['onChange'](e);
        }
    }

    const inp = <input ref={ref} className={"custom-checkbox"} id={!!props['id'] ? props['id'] : null} type={"checkbox"}
                       name={props['name']} value={props['value']} defaultChecked={!!props['defaultChecked']}
                       disabled={!!props['disabled']} onChange={handleChange}/>;

    if (ref?.current) {
        ref.current.onChange = () => {
            const syntheticE = new Event('change', {bubbles: true});
            ref.current.dispatchEvent(syntheticE);
            handleChange(syntheticE);
        };
    }

    const labelCx = classNames({
        "custom-checkbox-label": true,
        "checked": checked,
        "disabled": !!props['disabled']
    });

    return (
        <>
            {!!props['label'] ? (
                <label className={labelCx}>
                    {props['label']}
                    {inp}
                </label>
            ) : inp}
        </>
    )

});

export default Checkbox;
