import React, {useContext, useEffect, useState} from "react";

import './ReviewsTable.css';
import axios from "axios";
import {UserContext} from "../../user";
import ReviewsTableRow from "./ReviewsTableRow/ReviewsTableRow";
import LoadingIcon from "../LoadingIcon/LoadingIcon";

export default function ReviewsTable(props) {

    const [reviews, setReviews] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);

    const user = useContext(UserContext);

    useEffect(() => {

        let mounted = true;

        setLoading(true);

        axios.get(`${process.env.REACT_APP_API_URL}/v1/admin/reviews`, {
            headers: {
                Authorization: `Basic ${user['auth'][0]}`
            }
        })
            .then(response => {
                if (response.status === 200) {
                    const data = response.data.data;
                    if ('review_ids' in data) {
                        setReviews(data['review_ids']);
                    }
                }
                setLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setLoading(false);
            });

        return () => {
            mounted = false;
        }

    }, [])

    return (
        <div className={"reviews-table-area"}>
            {loading && <LoadingIcon/>}
            <p className={"error"}>{error}</p>
            <table className={"reviews-table"}>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Rating</th>
                    <th>Review</th>
                    <th>Date Posted</th>
                    {
                        reviews.length > 0 && (
                            <>
                                <th/>
                                <th/>
                            </>
                        )
                    }
                </tr>
                </thead>
                <tbody>
                {reviews.length === 0 && (
                    <tr>
                        <td>
                            <p>Nothing to show</p>
                        </td>
                    </tr>
                )}
                {reviews.map((item, index) => {
                    return (
                        <ReviewsTableRow key={`reviews-table-row-fragment-${index}`} id={item}
                                         onLoadChange={loadState => setLoading(loadState)}/>
                    )
                })}
                </tbody>
            </table>
        </div>
    )

}
