import React, {useContext, useEffect, useState} from "react";

import './About.css';
import Page from "../../Components/Page/Page";
import PageContent from "../../Components/PageContent/PageContent";
import PageContentRow from "../../Components/PageContentRow/PageContentRow";
import {Link} from "react-router-dom";
import LoadingIcon from "../../Components/LoadingIcon/LoadingIcon";
import {UserContext} from "../../user";
import axios from "axios";
import PageContentColumn from "../../Components/PageContentColumn/PageContentColumn";
import TermsAndConditionsTable from "../../Components/TermsAndConditionsTable/TermsAndConditionsTable";

export default function About(props) {

    const user = useContext(UserContext);

    const [about, setAbout] = useState("");
    const [aboutLoading, setAboutLoading] = useState(true);
    const [aboutResponse, setAboutResponse] = useState("");
    const [aboutError, setAboutError] = useState("");

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_API_URL}/v1/admin/about`, {
            headers: {
                Authorization: `Basic ${user['auth'][0]}`
            }
        })
            .then(response => {
                setAboutLoading(false);
                const data = response.data.data;
                switch (response.status) {
                    case 200:
                        setAbout(data.content);
                        break;
                    default:
                        setAboutError("Could not retrieve the About page article. Please try again later");
                }
            });

    }, []);

    const saveAbout = (e) => {
        e.preventDefault();
        setAboutLoading(true);
        setAboutResponse("");
        setAboutError("");
        axios.patch(`${process.env.REACT_APP_API_URL}/v1/admin/about`, {
            content: about
        }, {
            headers: {
                Authorization: `Basic ${user['auth'][0]}`
            }
        })
            .then(response => {
                setAboutLoading(false);
                const data = response.data;
                switch (response.status) {
                    case 200:
                        setAbout(data.content);
                        setAboutResponse("Changes saved successfully");
                        break;
                    default:
                        setAboutError("Could not update the About page article. Please try again later");
                }
            })
            .catch(error => {
                setAboutLoading(false);
                const response = error.response;
                setAboutError((response.data.message ?? "Could not update the About page article. Please try again later"));
            });
    }

    return (
        <Page className={"About"}>
            <PageContent>
                <PageContentRow>
                    <PageContentColumn>
                        <h2>Manage information on Legacy Cars.</h2>
                        <p className={"center"}>Change information about Legacy Cars.</p>
                    </PageContentColumn>
                </PageContentRow>
                <PageContentRow>
                    <PageContentColumn>
                        <p><Link to={"/"}>&lsaquo; Dashboard</Link></p>
                        <h3>About Legacy Cars.</h3>
                        <p>Oversee information about Legacy Cars.</p>
                        <form className={"horizontal dashboard-menu"}>
                            <Link to={'/about/reviews'}>
                                <input type={"button"} value={"See Reviews"}/>
                            </Link>
                        </form>
                        <form className={"block"} onSubmit={saveAbout}>
                            <h4>About</h4>
                            <p className={"response"}>{aboutResponse}</p>
                            <p className={"error"}>{aboutError}</p>
                            <label htmlFor={"about-input"}>
                                About article
                                <textarea id={"about-input"} name={"about"} value={about}
                                          onChange={e => setAbout(e.target.value)}/>
                            </label>
                            <input type={"submit"} disabled={aboutLoading} value={"Save Changes"}/>
                            {aboutLoading && <LoadingIcon/>}
                        </form>
                    </PageContentColumn>
                </PageContentRow>
                <PageContentRow>
                    <PageContentColumn>
                        <h3>Terms and Conditions.</h3>
                        <p>Change the Terms and Conditions for Legacy Cars.</p>
                        <TermsAndConditionsTable/>
                    </PageContentColumn>
                </PageContentRow>
            </PageContent>
        </Page>
    );
}
