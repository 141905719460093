import React, {useContext, useEffect, useState} from "react";
import axios from "axios";

import './ReviewsTableRow.css';
import {UserContext} from "../../../user";

export default function ReviewsTableRow(props) {

    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(true);

    const user = useContext(UserContext);

    const approveReview = reviewID => {
        setLoading(true);
        axios.patch(`${process.env.REACT_APP_API_URL}/v1/admin/reviews/${reviewID}`, {
            approved: true
        }, {
            headers: {
                Authorization: `Basic ${user['auth'][0]}`
            }
        })
            .then(response => {
                if (response.status === 200) {
                    setItem({
                        ...item,
                        verified: true
                    });
                }
                setLoading(false);
            })
    }

    const removeReview = reviewID => {
        setLoading(true);
        if (window.confirm("Are you sure you want to remove this review?")) {
            axios.delete(`${process.env.REACT_APP_API_URL}/v1/admin/reviews/${reviewID}`, {
                headers: {
                    Authorization: `Basic ${user['auth'][0]}`
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        setItem(null);
                        setLoading(false);
                    }
                })
        }
    }

    useEffect(() => {

        if (!!props['onLoadChange'] && typeof props['onLoadChange'] === 'function') {
            props['onLoadChange'](loading);
        }

    }, [loading]);

    useEffect(() => {

        let mounted = true;

        axios.get(`${process.env.REACT_APP_API_URL}/v1/admin/reviews/${props['id']}`, {
            headers: {
                Authorization: `Basic ${user['auth'][0]}`
            }
        })
            .then(response => {
                if (mounted) {
                    if (response.status === 200) {
                        const data = response.data.data;
                        if (data.length > 0) {
                            setItem(data[0]);
                        }
                    }
                    setLoading(false);
                }
            });

        return () => {
            mounted = false;
        }

    }, [props['id']])

    return !!item ? (
        <tr key={`reviews-table-row`}>
            <td>{item.name}</td>
            <td>{item.rating}/10</td>
            <td>{item.review}</td>
            <td>{new Date(Date.parse(item.timestamp))?.toDateString()}</td>
            <td>
                <form>
                    <input type={"button"} value={`Approve${!!item.verified ? "d" : ""}`}
                           onClick={() => approveReview(item.review_id)}
                           disabled={!!item.verified}/>
                </form>
            </td>
            <td>
                <form>
                    <input className={"remove-review-button"} type={"button"} value={"x"}
                           onClick={() => removeReview(item.review_id)}/>
                </form>
            </td>
        </tr>
    ) : null;

}
