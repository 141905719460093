import React, {useContext, useEffect, useState} from "react";

import './TermsAndConditionsTable.css';
import axios from "axios";
import {UserContext} from "../../user";
import LoadingIcon from "../LoadingIcon/LoadingIcon";
import PageContentRow from "../PageContentRow/PageContentRow";
import PageContentColumn from "../PageContentColumn/PageContentColumn";

export default function TermsAndConditionsTable(props) {

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [sectionNames, setSectionNames] = useState({});
    const [sectionErrors, setSectionErrors] = useState({});
    const [sectionResponses, setSectionResponses] = useState({});
    const [sectionFormLoading, setSectionFormLoading] = useState({});
    const [sectionTermFormVisible, setSectionTermFormVisible] = useState({});
    const [sectionTermFormLoading, setSectionTermFormLoading] = useState({});
    const [newSectionTerm, setNewSectionTerm] = useState({});

    const [focusSection, setFocusSection] = useState(null);

    //New section states
    const [newSectionResponse, setNewSectionResponse] = useState("");
    const [newSectionError, setNewSectionError] = useState("");
    const [newSectionNumber, setNewSectionNumber] = useState(1);
    const [newSectionName, setNewSectionName] = useState("New Section");
    const [newSectionGroupNumber, setNewSectionGroupNumber] = useState(0);
    const [newSectionTermContent, setNewSectionTermContent] = useState("");

    const user = useContext(UserContext);

    const createSection = () => {
        setNewSectionResponse("");
        setNewSectionError("");
        if (!/[0-9]+/.test(newSectionNumber)) {
            setNewSectionError("Invalid section number.");
            return;
        }
        if (Object.keys(data).includes(newSectionNumber)) {
            setNewSectionError(`Section ${newSectionNumber} already exists.`);
            return;
        }
        if (newSectionName === "") {
            setNewSectionError("Section name cannot be empty.");
            return;
        }
        if (!/[0-9]+/.test(newSectionGroupNumber)) {
            setNewSectionError("Invalid section group number.");
            return;
        }
        if (newSectionTermContent === "") {
            setNewSectionError("New term content cannot be empty.");
            return;
        }
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL}/v1/admin/about/terms-and-conditions/`, {
            section: newSectionNumber,
            section_name: newSectionName,
            section_group: newSectionGroupNumber,
            section_group_paragraph: 0,
            content: newSectionTermContent
        }, {
            headers: {
                Authorization: `Basic ${user['auth'][0]}`
            }
        })
            .then(response => {
                switch (response.status) {
                    case 200:
                        setNewSectionResponse(`Created section ${newSectionNumber} - "${newSectionName}" with term ${newSectionNumber}.${newSectionGroupNumber}.0`);
                        setData({
                            ...data,
                            [newSectionNumber]: {
                                "name": newSectionName,
                                "groups": {
                                    [newSectionGroupNumber]: {
                                        0: [{
                                            "content": newSectionTermContent,
                                            "originalContent": newSectionTermContent,
                                            "last_updated": (new Date(Date.now())).toDateString()
                                        }]
                                    }
                                }
                            }
                        });
                        setSectionNames({
                            ...sectionNames,
                            [newSectionNumber]: newSectionName
                        });
                        setNewSectionNumber(newSectionNumber + 1);
                        setNewSectionName("New Section");
                        setNewSectionGroupNumber(0);
                        setNewSectionTermContent("");
                        break;
                    default:
                        setNewSectionError(`Could not create section: ${response.data.message}`);
                }
                setLoading(false);
            })
            .catch(error => {
                setNewSectionError(`Could not create section: ${error.message}`);
                setLoading(false);
            });
    }

    const deleteSection = (section) => {
        setLoading(true);
        axios.delete(`${process.env.REACT_APP_API_URL}/v1/admin/about/terms-and-conditions/section/${section}`, {
            headers: {
                Authorization: `Basic ${user['auth'][0]}`
            }
        })
            .then(response => {
                switch (response.status) {
                    case 200:
                        let tempErrors = {...sectionErrors};
                        delete tempErrors[section];
                        setSectionErrors(tempErrors);
                        let tempResponses = {...sectionResponses};
                        delete tempResponses[section];
                        setSectionResponses(tempResponses);
                        let tempFormLoading = {...sectionFormLoading};
                        delete tempFormLoading[section];
                        setSectionFormLoading(tempFormLoading);
                        let tempSectionNames = {...sectionNames};
                        delete tempSectionNames[section];
                        setSectionNames(tempSectionNames);
                        let tempSectionTermFormVisible = {...sectionTermFormVisible};
                        delete tempSectionTermFormVisible[section];
                        setSectionTermFormVisible(tempSectionTermFormVisible);
                        let tempSectionTermFormLoading = {...sectionTermFormLoading};
                        delete tempSectionTermFormLoading[section];
                        setSectionTermFormLoading(tempSectionTermFormLoading);
                        let tempData = {...data};
                        delete tempData[section];
                        setData(tempData);
                        setLoading(false);
                        break;
                    default:
                        setSectionErrors({
                            ...sectionErrors,
                            [section]: `Could not delete section: ${response.data.message}`
                        });
                        setLoading(false);
                        setFocusSection(section);
                }
            })
            .catch(error => {
                setSectionErrors({
                    ...sectionErrors,
                    [section]: `Could not delete section: ${error.message}`
                });
                setLoading(false);
                setFocusSection(section);
            });

    }

    const updateSectionName = (section) => {
        if ((section in data) && data[section]['name'] === sectionNames[section]) {
            return;
        }
        setSectionFormLoading({
            ...sectionFormLoading,
            [section]: true
        });
        setSectionErrors({
            ...sectionErrors,
            [section]: ""
        });
        setSectionResponses({
            ...sectionResponses,
            [section]: ""
        });
        axios.patch(`${process.env.REACT_APP_API_URL}/v1/admin/about/terms-and-conditions/section/${section}`, {
            name: sectionNames[section]
        }, {
            headers: {
                Authorization: `Basic ${user['auth'][0]}`
            }
        })
            .then(response => {
                switch (response.status) {
                    case 200:
                        setSectionResponses({
                            ...sectionResponses,
                            [section]: "Section name saved successfully"
                        });
                        break;
                    default:
                        setSectionErrors({
                            ...sectionErrors,
                            [section]: `Could not save section name: ${response.data.message}`
                        });
                }
                setSectionFormLoading({
                    ...sectionFormLoading,
                    [section]: false
                });
                setFocusSection(section);
            })
            .catch(error => {
                setSectionErrors({
                    ...sectionErrors,
                    [section]: `Could not save section name: ${error.message}`
                });
                setSectionFormLoading({
                    ...sectionFormLoading,
                    [section]: false
                });
                setFocusSection(section);
            });
    }

    const createTerm = (section) => {
        if (!newSectionTerm[section] || !Object.keys(newSectionTerm[section]).includes("content") || newSectionTerm[section]['content'] === "" || !(/^[0-9]+$/.test(newSectionTerm[section]['group'] ?? "")) || !(/^[0-9]+$/.test(newSectionTerm[section]['paragraph'] ?? ""))) {
            return;
        }
        setSectionTermFormLoading({
            ...sectionTermFormLoading,
            [section]: true
        });
        setSectionErrors({
            ...sectionErrors,
            [section]: ""
        });
        setSectionResponses({
            ...sectionResponses,
            [section]: ""
        });
        const group = parseInt(newSectionTerm[section]['group']);
        const paragraph = parseInt(newSectionTerm[section]['paragraph']);
        const content = newSectionTerm[section]['content'];
        axios.post(`${process.env.REACT_APP_API_URL}/v1/admin/about/terms-and-conditions`, {
            section: section,
            section_group: group,
            section_group_paragraph: paragraph,
            content: content

        }, {
            headers: {
                Authorization: `Basic ${user['auth'][0]}`
            }
        })
            .then(response => {
                switch (response.status) {
                    case 200:
                        setSectionResponses({
                            ...sectionResponses,
                            [section]: `Saved term ${section}.${group}.${paragraph}`
                        });
                        setData({
                            ...data,
                            [section]: {
                                ...data[section],
                                "groups": {
                                    ...data[section]['groups'],
                                    [group]: {
                                        ...data[section]['groups'][group],
                                        [paragraph]: [{
                                            content: content,
                                            originalContent: content,
                                            last_updated: new Date(Date.now())?.toDateString()
                                        }]
                                    }
                                }
                            }
                        });
                        const temp = {...newSectionTerm};
                        delete temp[section];
                        setNewSectionTerm(temp);
                        setSectionTermFormVisible({
                            ...sectionTermFormVisible,
                            [section]: false
                        });
                        break;
                    default:
                        setSectionErrors({
                            ...sectionErrors,
                            [section]: `Could not save term: ${response.data.message}`
                        });
                }
                setSectionTermFormLoading({
                    ...sectionTermFormLoading,
                    [section]: false
                });
                setFocusSection(section);
            })
            .catch(error => {
                setSectionErrors({
                    ...sectionErrors,
                    [section]: `Could not save term: ${error.message}`
                });
                setSectionTermFormLoading({
                    ...sectionTermFormLoading,
                    [section]: false
                })
                setFocusSection(section);
            })
    }

    const deleteTerm = (section, group, paragraph) => {
        setSectionErrors({
            ...sectionErrors,
            [section]: ""
        });
        setSectionResponses({
            ...sectionResponses,
            [section]: ""
        });
        setLoading(true);
        axios.delete(`${process.env.REACT_APP_API_URL}/v1/admin/about/terms-and-conditions`, {
            headers: {
                Authorization: `Basic ${user['auth'][0]}`
            },
            data: {
                section: section,
                section_group: group,
                section_group_paragraph: paragraph
            }
        })
            .then(response => {
                switch (response.status) {
                    case 200:
                        setSectionResponses({
                            ...sectionResponses,
                            [section]: `Deleted term ${section}.${group}.${paragraph}`
                        });
                        const tempData = {...data};
                        delete tempData[section]['groups'][group][paragraph];
                        if (Object.keys(tempData[section]['groups'][group]).length === 0) {
                            delete tempData[section]['groups'][group];
                            if (Object.keys(tempData[section]['groups']).length === 0) {
                                delete tempData[section];
                            }
                        }
                        setData(tempData);
                        break;
                    default:
                        setSectionErrors({
                            ...sectionErrors,
                            [section]: `Could not delete term: ${response.data.message}`
                        });
                }
                setLoading(false);
                setFocusSection(section);
            })
            .catch(error => {
                setSectionErrors({
                    ...sectionErrors,
                    [section]: `Could not delete term: ${error.message}`
                });
                setLoading(false);
                setFocusSection(section);
            })
    }

    const changeTerm = (section, group, paragraph, content) => {
        setData({
            ...data,
            [section]: {
                ...data[section],
                "groups": {
                    ...data[section]['groups'],
                    [group]: {
                        ...data[section]['groups'][group],
                        [paragraph]: [{
                            ...data[section]['groups'][group][paragraph][0],
                            content: content
                        }]
                    }
                }
            }
        });
    }

    const updateTerm = (section, group, paragraph, content) => {
        setSectionErrors({
            ...sectionErrors,
            [section]: ""
        });
        setSectionResponses({
            ...sectionResponses,
            [section]: ""
        });
        setLoading(true);
        axios.patch(`${process.env.REACT_APP_API_URL}/v1/admin/about/terms-and-conditions`, {
            section: section,
            section_group: group,
            section_group_paragraph: paragraph,
            content: content
        }, {
            headers: {
                Authorization: `Basic ${user['auth'][0]}`
            }
        })
            .then(response => {
                switch (response.status) {
                    case 200:
                        setSectionResponses({
                            ...sectionResponses,
                            [section]: `Saved term ${section}.${group}.${paragraph}`
                        });
                        setData({
                            ...data,
                            [section]: {
                                ...data[section],
                                "groups": {
                                    ...data[section]['groups'],
                                    [group]: {
                                        ...data[section]['groups'][group],
                                        [paragraph]: [{
                                            ...data[section]['groups'][group][paragraph][0],
                                            originalContent: data[section]['groups'][group][paragraph][0]['content']
                                        }]
                                    }
                                }
                            }
                        });
                        break;
                    default:
                        setSectionErrors({
                            ...sectionErrors,
                            [section]: `Could not update term: ${response.data.message}`
                        });
                }

                setLoading(false);
            })
            .catch(error => {
                setSectionErrors({
                    ...sectionErrors,
                    [section]: `Could not update term: ${error.message}`
                });
                setLoading(false);
                setFocusSection(section);
            });
    }

    useEffect(() => {

        if (focusSection) {
            const sectionElement = document.getElementById(`tac-section-${focusSection}`);
            if (sectionElement) {
                sectionElement.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest"
                });
                setFocusSection(null);
            }
        }

    }, [focusSection])

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_API_URL}/v1/admin/about/terms-and-conditions`, {
            headers: {
                Authorization: `Basic ${user['auth'][0]}`
            }
        })
            .then(response => {
                switch (response.status) {
                    case 200:
                        const responseData = response.data?.data;
                        if (responseData) {
                            for (let section of Object.keys(responseData)) {
                                for (let group of Object.keys(responseData[section]['groups'])) {
                                    for (let paragraph of Object.keys(responseData[section]['groups'][group])) {
                                        responseData[section]['groups'][group][paragraph] = [
                                            {
                                                ...responseData[section]['groups'][group][paragraph][0],
                                                originalContent: responseData[section]['groups'][group][paragraph][0]['content']
                                            }
                                        ]
                                    }
                                }
                            }
                            setNewSectionNumber(Math.max(...Object.keys(responseData ?? {"0": ""}).map(item => parseInt(item))) + 1);
                            const tempSectionNames = {};
                            for (let section of Object.keys(responseData)) {
                                tempSectionNames[section] = responseData[section]['name'];
                            }
                            setSectionNames(tempSectionNames);
                            setData(responseData);
                        } else {
                            setError(response.data.message);
                        }
                        break;
                    default:
                        setError(response.data.message);
                }
                setLoading(false);
            })
            .catch(error => {
                setError("Could not load Terms and Conditions");
                setLoading(false);
            });

    }, []);

    return (
        <div className={"tac-table-area"}>
            {loading && <LoadingIcon/>}
            <p className={"error"}>{error}</p>
            {
                data.length === 0 ? (
                    <table className={"tac-table"}>
                        <thead>
                        <tr>
                            <th>Section</th>
                            <th>Group</th>
                            <th>Paragraph</th>
                            <th>Content</th>
                            <th>Last Updated</th>
                            <th>Options</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <p>{"Nothing to show"}</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                ) : (
                    <>
                        <nav>
                            <ul>
                                <li>
                                    <form>
                                        <input type={"button"}
                                               value={`+ Add a New Section`}
                                               style={{
                                                   marginRight: 40
                                               }}
                                               onClick={() => {
                                                   const elem = document.getElementById(`tac-new-section-form`);
                                                   if (elem) {
                                                       elem.scrollIntoView({
                                                           behavior: "smooth",
                                                           block: "start",
                                                           inline: "nearest"
                                                       });
                                                   }
                                               }}/>
                                    </form>
                                </li>
                                {Object.keys(data).map((section, sectionIndex) => (
                                    <li key={`tac-section-nav-${sectionIndex}`}>
                                        <form>
                                            <input type={"button"}
                                                   value={`Section ${section} – ${sectionNames[section]}`}
                                                   onClick={() => {
                                                       const elem = document.getElementById(`tac-section-${section}`);
                                                       if (elem) {
                                                           elem.scrollIntoView({
                                                               behavior: "smooth",
                                                               block: "start",
                                                               inline: "nearest"
                                                           });
                                                       }
                                                   }}/>
                                        </form>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                        {Object.keys(data).map((section, sectionIndex) => (
                            <PageContentRow key={`tac-section-area-${sectionIndex}`} id={`tac-section-${section}`}
                                            className={`tac-section-area`}>
                                <PageContentColumn style={{padding: 0}}>
                                    <h3>Section {section} – {sectionNames[section]}</h3>
                                    <p className={"response"}>{sectionResponses[section]}</p>
                                    <p className={"error"}>{sectionErrors[section]}</p>
                                    <form className={"block tac-table-section-form"} onSubmit={e => {
                                        e.preventDefault();
                                        updateSectionName(section);
                                    }}>
                                        {
                                            !!sectionFormLoading[section] && <LoadingIcon/>
                                        }
                                        <label>
                                            Section Name
                                            <input type={"text"} placeholder={"Section name"}
                                                   value={sectionNames[section]}
                                                   onChange={(e) =>
                                                       setSectionNames({...sectionNames, [section]: e.target.value})
                                                   }
                                                   pattern={"[A-Za-z0-9\\s]+[^\\s]"}
                                                   disabled={!!sectionFormLoading[section]}
                                                   required/>
                                        </label>
                                        <span className={"horizontal"}>
                                        <input type={"submit"} value={"Save Changes"}
                                               disabled={sectionNames[section] === data[section]['name'] || !!sectionFormLoading[section]}/>
                                        <input type={"button"} className={"destructive"} value={"Delete Section"}
                                               onClick={() => {
                                                   if (window.confirm(`Are you sure you want to delete Section ${section} ("${sectionNames[section]}")? This deletes all terms within the section, and this cannot be undone.`)) {
                                                       deleteSection(section);
                                                   }
                                               }}/>
                                    </span>
                                    </form>
                                    <form onSubmit={e => {
                                        e.preventDefault();
                                        setSectionTermFormVisible({
                                            ...sectionTermFormVisible,
                                            [section]: true
                                        });
                                    }}>
                                        <input type={"submit"} value={"+ Create a New Term"}
                                               disabled={!!sectionTermFormVisible[section]}/>
                                    </form>
                                    {!!sectionTermFormVisible[section] && (
                                        <form className={"block tac-table-section-form"} onSubmit={e => {
                                            e.preventDefault();
                                            createTerm(section);
                                        }}>
                                            {
                                                !!sectionTermFormLoading[section] && <LoadingIcon/>
                                            }
                                            <h4>{!!newSectionTerm[section] && newSectionTerm[section]['group'] !== "" ? `Draft Term: ${section}.${newSectionTerm[section]['group']}.${!!newSectionTerm[section] && !!newSectionTerm[section]['group'] ? Math.max(...Object.keys(data[section]['groups'][newSectionTerm[section]['group']] ?? {"-1": ""}).map(item => parseInt(item))) + 1 : 0}` : `Create a new term in Section ${section} ("${sectionNames[section]}").`}</h4>
                                            <input type={"hidden"} name={"section"} value={section}/>
                                            <label>
                                                Term Group Number
                                                <input type={"text"} pattern={"[0-9]+"} name={"group"}
                                                       placeholder={"Group number"}
                                                       onChange={e => {
                                                           setNewSectionTerm({
                                                               ...newSectionTerm,
                                                               [section]: {
                                                                   ...newSectionTerm[section],
                                                                   group: e.target.value,
                                                                   paragraph: !!e.target.value ? Math.max(...Object.keys(data[section]['groups'][e.target.value] ?? {"-1": ""}).map(item => parseInt(item))) + 1 : 0
                                                               }
                                                           });
                                                       }} required/>
                                            </label>
                                            <label>
                                                Paragraph Number
                                                <input type={"text"} disabled={true} name={"paragraph"}
                                                       placeholder={"Paragraph number"}
                                                       value={!!newSectionTerm[section] && !!newSectionTerm[section]['group'] ? Math.max(...Object.keys(data[section]['groups'][newSectionTerm[section]['group']] ?? {"-1": ""}).map(item => parseInt(item))) + 1 : 0}/>
                                            </label>
                                            <label>
                                                Term Content
                                                <textarea type={"text"} placeholder={"Term content"} onChange={e => {
                                                    setNewSectionTerm({
                                                        ...newSectionTerm,
                                                        [section]: {
                                                            ...newSectionTerm[section],
                                                            content: e.target.value
                                                        }
                                                    });
                                                }} required/>
                                            </label>
                                            <span className={"horizontal"}>
                                            <input type={"submit"} value={"+ Create Term"}
                                                   disabled={!newSectionTerm[section] || newSectionTerm[section]['content'] === "" || !(/^[0-9]+$/.test(newSectionTerm[section]['group'] ?? ""))}/>
                                            <input className={"destructive"} type={"button"} value={"Cancel"}
                                                   onClick={() => {
                                                       if (window.confirm("All progress will be lost. Are you sure you want to cancel?")) {
                                                           setSectionTermFormVisible({
                                                               ...sectionTermFormVisible,
                                                               [section]: false
                                                           });
                                                       }
                                                   }}/>
                                        </span>
                                        </form>
                                    )}
                                    <div className={"tac-table-container"}>
                                        <table className={"tac-table"}>
                                            <thead>
                                            <tr>
                                                <th>Section</th>
                                                <th>Group</th>
                                                <th>Paragraph</th>
                                                <th>Content</th>
                                                <th>Last Updated</th>
                                                <th>Options</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                Object.keys(data[section]['groups']).map((group, groupIndex) => (
                                                    Object.keys(data[section]['groups'][group]).map((paragraph, paragraphIndex) => {
                                                        return (
                                                            <tr key={`tac-section-${section}-${group}-${paragraph}`}>
                                                                <td className={"bold"}>{section}</td>
                                                                <td className={"bold"}>{group}</td>
                                                                <td className={"bold"}>{paragraph}</td>
                                                                <td style={{minWidth: 'min(300pt, 50vw)'}}>
                                                                    <form>
                                                                    <textarea
                                                                        defaultValue={data[section]['groups'][group][paragraph][0]['content']}
                                                                        placeholder={"Term or condition text"}
                                                                        onChange={e => changeTerm(section, group, paragraph, e.target.value)}
                                                                        required/>
                                                                    </form>
                                                                </td>
                                                                <td>{new Date(Date.parse(data[section]['groups'][group][paragraph][0]['last_updated']))?.toDateString()}</td>
                                                                <td>
                                                                    <form onSubmit={e => {
                                                                        e.preventDefault();
                                                                        updateTerm(section, group, paragraph, data[section]['groups'][group][paragraph][0]['content']);
                                                                    }}>
                                                                        <input type={"submit"} value={"Save Changes"}
                                                                               disabled={data[section]['groups'][group][paragraph][0]['content'] === data[section]['groups'][group][paragraph][0]['originalContent']}/>
                                                                        <input type={"button"} className={"destructive"}
                                                                               value={"Delete Term"} onClick={() => {
                                                                            if (window.confirm("Are you sure you want to delete this term? This cannot be undone.")) {
                                                                                deleteTerm(section, group, paragraph);
                                                                            }
                                                                        }
                                                                        }/>
                                                                    </form>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </PageContentColumn>
                            </PageContentRow>)
                        )}
                        <PageContentRow id={"tac-new-section-form"}>
                            <PageContentColumn>
                                <h3>Add a New Section</h3>
                                <p>Add a new section to group new terms and conditions.</p>
                                <p className={"response"}>{newSectionResponse}</p>
                                <p className={"error"}>{newSectionError}</p>
                                <form className={"block tac-table-section-form"} onSubmit={e => {
                                    e.preventDefault();
                                    createSection();
                                }}>
                                    <h4>Create a new section with a new term.</h4>
                                    <label>
                                        Section Name
                                        <input type={"text"} name={"name"}
                                               value={newSectionName}
                                               onChange={e => setNewSectionName(e.target.value)}/>
                                    </label>
                                    <br/>
                                    <label>
                                        Section Number
                                        <input type={"text"} pattern={"[0-9]+"} name={"section"}
                                               value={newSectionNumber}
                                               onChange={e => {
                                                   if (isNaN(parseInt(e.target.value))) {
                                                       setNewSectionNumber(e.target.value);
                                                       return;
                                                   }
                                                   setNewSectionNumber(parseInt(e.target.value));
                                               }} required/>
                                    </label>
                                    <label>
                                        First Group Number
                                        <input type={"text"} pattern={"[0-9]+"} name={"group"}
                                               placeholder={"Group number"} value={newSectionGroupNumber}
                                               onChange={e => setNewSectionGroupNumber(e.target.value)} required/>
                                    </label>
                                    <label>
                                        First Paragraph Number
                                        <input type={"text"} disabled={true} name={"paragraph"}
                                               value={0} placeholder={"Paragraph number"} required/>
                                    </label>
                                    <br/>
                                    <h4>New draft for
                                        term {!isNaN(parseInt(newSectionNumber)) ? newSectionNumber : Math.max(...Object.keys(data ?? {"0": ""}).map(item => parseInt(item))) + 1}.{!isNaN(parseInt(newSectionGroupNumber)) ? newSectionGroupNumber : 0}.0</h4>
                                    <label>
                                        Term Content
                                        <textarea placeholder={"Term content"} value={newSectionTermContent}
                                                  onChange={e => setNewSectionTermContent(e.target.value)} required/>
                                    </label>
                                    <input type={"submit"}
                                           value={`+ Create Section ${!isNaN(parseInt(newSectionNumber)) ? newSectionNumber : Math.max(...Object.keys(data ?? {"0": ""}).map(item => parseInt(item))) + 1}`}/>
                                </form>
                            </PageContentColumn>
                        </PageContentRow>
                    </>
                )
            }
        </div>
    );
}
